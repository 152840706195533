.footer {
  position: relative;
  background: linear-gradient(103.02deg, var(--cl-navy) -1.78%, var(--cl-purple) 119.69%);
  overflow: hidden;

  .cont-second {
    @mixin xl {
      width: 92.00%;
      max-width: 1288px;
    }
  }

  &__wrap {
    padding-top: 227px;
    padding-bottom: ac(37px, 40px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    z-index: 1;
    max-width: 1278px;
    margin-left: auto;
    margin-right: auto;
  }

  &__bg-decor {
    @mixin aspect-ratio 1432.46, 1221.97;
    position: absolute;
    width: ac(1432px, 1038px);
    top: ac(-102px, 189px);
    right: ac(38px, -488px);
    z-index: -1;
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__content {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: ac(42px, 23px);
    }
  }

  &__logo {
    @mixin aspect-ratio 217, 50;
    width: 217px;

    &:not(:last-child) {
      margin-bottom: ac(29px, 27px);
    }
  }

  &__contacts {
    width: 54%;
    max-width: 634px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: ac(8px, 24px);
    margin-right: ac(4px, 0px);
  }

  &__contact-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 38.49%;

    &.with-location {
      width: 56.78%;
    }

    .footer__link {
      &:not(:last-child) {
        margin-bottom: ac(8px, 7px);
      }
    }
  }

  &__contact-title {
    font-family: var(--font-second);
    font-weight: 700;
    font-size: ac(24px, 20px);
    line-height: normal;
    color: var(--cl-white);

    &:not(:last-child) {
      margin-bottom: ac(17px, 8px);
    }
  }

  &__link {
    position: relative;
    font-family: var(--font-main);
    font-size: ac(18px, 16px);
    font-weight: 400;
    line-height: normal;
    transition: color .3s ease;
    color: var(--cl-white);

    &:before {
      right: 0;
      left: auto;
      bottom: 0;
      width: 0;
      height: 1px;
      background: var(--cl-white);
      opacity: 0;
      transition: width .3s ease, opacity .3s ease;
      content: "";
      position: absolute;
    }

    &:hover {
      color: var(--cl-turquoise);

      &:before {
        right: auto;
        left: 0;
        width: 100%;
        opacity: 1;
        color: var(--cl-turquoise);
      }
    }

    &.with-location {
      &:before {
        content: none;
      }
    }
  }

  &__socials {
    &:not(:last-child) {
      margin-bottom: 21px;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(39px, 24px);
    grid-row-gap: 16px;
    flex-wrap: wrap;

    @mixin media 1200 {
      grid-column-gap: 35px;
    }

    @mixin media 1020 {
      justify-content: center;
    }

    @mixin max-sm {
      grid-row-gap: 16px;
    }

    @mixin media 390 {
      max-width: 290px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__nav-link {
    font-family: var(--font-second);
    position: relative;
    z-index: 1;
    font-size: ac(18px, 16px);
    line-height: 18px;
    font-weight: 500;
    transition: color .3s ease;
    color: var(--cl-white);

    @mixin max-sm {
      i {
        display: none;
      }
    }

    i {
      position: absolute;
      right: 0;
      opacity: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity .1s ease, transform .3s ease, color .3s ease;
      font-size: 12px;
      z-index: -1;
      color: var(--cl-white);
    }

    &:hover {
      color: var(--cl-turquoise);

      i {
        transition: opacity .3s ease, transform .3s ease, color .3s ease;
        transform: translateX(calc(100% + 3px)) translateY(-50%);
        opacity: 1;
        color: var(--cl-turquoise);
      }
    }
  }

  &__bottom {
    border-top: 1px solid rgba(225, 232, 255, 0.3);
    padding-top: ac(40px, 24px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    grid-row-gap: 24px;
    grid-column-gap: ac(20px, 16px);
  }

  &__bottom-info {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }

  &__developer {
    margin-top: ac(24px, 22px);
    width: 150px;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @mixin media 1050 {
    .footer {
      &__bottom {
        grid-column-gap: 20px;
      }

      &__nav {

      }
    }
  }

  @mixin media 951 {
    .footer {
      &__contacts {
        margin-top: 0;
        width: 60%;
      }

      &__contact-item {
        &:not(:last-child) {
          margin-bottom: ac(20px, 17px);
        }
      }
    }
  }

  @mixin media 801 {
    .footer {
      &__contacts {
        flex-direction: column;
        padding-bottom: 24px;
        width: 50%;
      }

      &__contact-item {
        width: 100%;
      }

      &__contact-list {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
      }
    }
  }

  @mixin max-md {
    .footer {
      &__container {
        flex-direction: column;
        align-items: center;
      }

      &__content {
        width: 100%;
        align-items: center;
      }

      &__contacts {
        width: 100%;
        align-items: center;
      }

      &__contact-item {
        align-items: center;
      }

      &__link {
        text-align: center;
        &.with-location {
          width: 100%;
          max-width: 340px;
        }
      }

      &__bottom {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        grid-column-gap: 16px;
      }

      &__nav {
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
      }

      &__developer {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @mixin max-sm {
    .footer {
      &__wrap {
        padding-top: 277px;
      }
    }
  }

  @mixin media 551 {
    .footer {
      &__nav {
        max-width: 300px;
        grid-column-gap: 0;
      }

      &__nav-link {
        margin-left: auto;
        margin-right: auto;

        &:nth-child(1) {
          transform: translateX(-5px);
        }

        &:nth-child(2) {
          transform: translateX(5px);
        }

        &:nth-child(3) {
          transform: translateX(-10px);
        }
      }
    }
  }
}

@media (max-width: 370px) {
  .footer__nav-link{
    font-size: 14px;
  }
  .footer .footer__nav-link:nth-child(3){
    transform: none;
  }
}