.testimonials-section {
  position: relative;
  z-index: 5;
  margin-top: ac(-390px, -471px);
  padding-top: ac(390px, 471px);
  padding-bottom: ac(80px, 40px);
  overflow: hidden;
  border-top-left-radius: 5px;


  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: ac(-34px, -220px);
    bottom: ac(-221px, 0px);
    z-index: -2;

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    @mixin min-media 1500 {
      img {
        object-fit: cover;
      }
    }

    &--mob {
      @mixin sm {
        display: none;
      }
    }

    &--desk {
      @mixin max-sm {
        display: none;
      }
    }
  }


  &__wrap {
    padding-top: ac(120px, 56px);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    grid-column-gap: ac(30px, 24px);

    @mixin max-md {
      flex-direction: column;
    }
  }

  &__content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;

    .slider-buttons {
      justify-content: flex-end;
      position: relative;
      z-index: 10;

      .slider-btn {
        @mixin xl {
          margin-top: 0;
          margin-bottom: ac(24px, 16px);
        }

        @mixin max-sm {
          margin-top: 28px;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(42px, 32px);
    }
  }

  &__title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: ac(740px, 270px);
    position: relative;

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__duplicate-title {
    font-size: ac(124px, 36px);
    font-weight: 800;
    line-height: normal;
    font-family: var(--font-second);
    color: var(--cl-white);
    mix-blend-mode: overlay;
    position: absolute;
    left: ac(0px, 17px);
    top: ac(-10px, 6px);
    width: ac(1050px, 310px);
    opacity: 0;
    transition: opacity 1s ease, transform 1s ease;
    will-change: opacity, transform;

    span {
      &:first-child {
        margin-left: ac(295px, 80px);

        @mixin max-xs {
          margin-left: 40px;
        }
      }
    }
  }

  &__title {
    position: relative;
    z-index: 1;

    &.animated {

      + .testimonials-section__duplicate-title {
        opacity: .3;
      }
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;

    &:not(.swiper-initialized) {
      display: none;
    }

    @mixin media 551 {
      overflow: visible;
    }
  }

  &__cubes {
    @mixin aspect-ratio-block 1279.8, 1173.3;
    position: absolute;
    bottom: ac(-340px, 188px);
    right: ac(-248px, -220px);
    width: 99.83%;
    min-width: 607px;
    max-width: 1279px;
    z-index: -1;
    opacity: .2;
    pointer-events: none;
    /*mix-blend-mode: soft-light;*/
  }

  &__cube {
    &:nth-child(1) {
      @mixin aspect-ratio 983.57, 887.1;
      width: 76.85%;
      position: absolute;
      top: 0;
      right: 0;
    }

    &:nth-child(2) {
      @mixin aspect-ratio 769.4, 769.3;
      width: 60.12%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }

  @mixin max-sm {
    .testimonials-section {
      &__content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__title-container {
        margin-bottom: 0;
      }
    }
  }

  + .stats-section {
    margin-top: ac(80px, 48px);
  }
}
