.btn {
  display: inline-flex;
  cursor: pointer;
  width: fit-content;
  justify-content: center;
  align-items: center;
  padding: 12px 25px;
  height: 54px;
  transition: all .3s ease;
  background: var(--cl-white);
  border: 2px solid var(--cl-lilac);
  overflow: hidden;
  position: relative;
  border-radius: 60px;
  grid-column-gap: 16px;
  flex-shrink: 0;
  box-shadow: 2px 4px 8px 0 rgba(0,0,0,0);

  &__text {
    width: 100%;
    font-family: var(--font-main);
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: var(--cl-navy);
    position: relative;
    z-index: 2;
    transition: all .3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: var(--cl-navy);
    transition: transform .3s ease;
    transform-origin: left;
  }

  &:hover {
    border-color: var(--cl-turquoise);
    background-color: var(--cl-turquoise);

    transform: translateY(-2px);
    box-shadow: 2px 4px 8px 0 rgba(0,0,0,0.2);
    .btn__text{
      color: var(--cl-white);
    }
    .btn__icon {
      transform: translateX(50%);
      color: var(--cl-white);
    }

  }

  &:active {
    transform: scale(0.9);
    opacity: .8;
  }

  &.btn-navy {
    background-color: var(--cl-navy);
    border-color: var(--cl-navy);

    .btn__text {
      color: var(--cl-white);
    }

    &:hover {
      border-color: var(--cl-navy);
      background-color: #0c2a57;

      .btn__text {
        color: var(--cl-turquoise);
      }
    }
  }

  &.btn-transparent{
    border: 1px solid var(--cl-white);
    background: transparent;
    color: var(--cl-white);
   .btn__text, .btn__icon{
     color: inherit;
   }
    &:hover{
      background: var(--cl-turquoise);
    }
  }

  &.btn-reverse{
    flex-direction: row-reverse;
    &:hover{
      .btn__icon{
        transform: translateX(-50%);
      }
    }
  }

  &.btn-upload{
    .btn__icon{
      transform: none;
    }
    &:not(:hover){
      .btn__icon{
        color: var(--cl-blue);
      }
    }
  }

}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-row-gap: ac(10px, 8px);
  grid-column-gap: ac(17px, 8px);
}

.slider-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: ac(62px, 56px);
  height: ac(62px, 56px);
  font-size: 22px;
  /*font-size: 32px;*/
  color: var(--cl-white);
  border: 1px solid var(--cl-white);
  border-radius: 50%;
  transition: border-color .3s ease, color .3s ease, opacity .3s ease, background-color .3s ease;
  background-color: transparent;
  cursor: pointer;
  margin-top: 32px;

  &.swiper-button-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    &:not(.swiper-button-disabled) {
      border-color: var(--cl-navy);
      background-color: var(--cl-lilac);
      color: var(--cl-navy);
    }
  }

  &.swiper-button-lock {
    display: none;
  }

  /*&.slider-btn-second {
    color: var(--cl-beige);


    &:hover {
      &:not(.swiper-button-disabled) {
        color: var(--cl-black);
      }
    }
  }*/

  &.slider-btn-navy {
    border-color: var(--cl-navy);
    color: var(--cl-navy);
    background: transparent;

    &:hover {
      background: transparent;
      &:not(.swiper-button-disabled) {
        border-color: var(--cl-purple);
        color: var(--cl-purple);
      }
    }
  }
}

.slider-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-gap: 12px;
}

.link-btn {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: 12px;
  position: relative;

  &:before {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    height: 1px;
    background: var(--cl-black);
    content: "";
  }

  &__text {
    font-size: ac(20px, 16px);
    line-height: 33px;
    font-weight: 600;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12.65px;
    color: var(--cl-black);
    transition: transform .3s ease;
    margin-top: ac(1px, 2px);
  }

  &:hover {
    .link-btn__icon {
      transform: translateX(100%);
    }
  }
}

.buttons-links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-row-gap: ac(10px, 8px);
  grid-column-gap: ac(17px, 8px);
}

.social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  color: var(--cl-turquoise);
  margin: ac(3.75px, 0px);
  transition: color .3s, transform .3s;
  will-change: color, transform;
  &:hover {
    color: var(--cl-white);
    transform: scale(1.2);
  }
}

.socials {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.simple-btn{
  color: var(--cl-white);
  display: inline-flex;
  align-items: center;
  width: fit-content;
  gap: 22px;
  transition: color 0.3s;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.44;
  text-shadow: 0 0 10px rgba(0,0,0,0);
  padding-left: 4px;
  i{
    font-size: 15px;
  }
  &:hover{
    color: var(--cl-turquoise);
    text-shadow: 0 0 10px rgba(0,0,0,0.2);
  }
}

.filters-group-toggle{
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 11px;
  font-size: ac(18px, 16px);
  color: var(--cl-blue);
  font-weight: 500;
  line-height: 1.44;
  cursor: pointer;
  transition: 0.3s;
  &-text{
    span{
      &:nth-of-type(2){
        display: none;
      }
    }
  }
  &.active{
    color: var(--cl-navy);
    .filters-group-toggle-text{

      span{
        display: none;
        &:nth-of-type(2){
          display: inline-block;
        }
      }
    }
    .plus-btn{
      &:before{
        background: var(--cl-navy);
      }
      &::after{
        opacity: 0;
      }
    }
  }

  &:hover{
    color: var(--cl-turquoise);
    .plus-btn{

      border-color: var(--cl-turquoise);
      background: var(--cl-turquoise);
      &::before, &::after{
        background: var(--cl-white);
      }

    }
  }
}

.plus-btn{
  position: relative;
   display: block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid rgba(6,19,68,0.15);
  background: var(--cl-white);
  cursor: pointer;
  transition: 0.3s;
  &::before, &::after{
    content: '';
    display:block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 3px;
    border-radius: 5px;
    background: var(--cl-blue);
    transition: 0.3s;
  }
  &::after{
    width: 3px;
    height: 15px;
  }

  &.active{
    &::after{
      opacity: 0;
    }
  }

  &:hover{
    border-color: var(--cl-turquoise);
    background: var(--cl-turquoise);
    &::before, &::after{
      background: var(--cl-white);
    }
  }
}

.upload-btn-wr {
  width: 183px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--cl-navy);
  cursor: pointer;
  gap: 8px;
  justify-content: flex-end;
  input {
    display: none !important;
  }

  .upload-btn {
    flex-shrink: 0;
    font-size: ac(18px, 16px);
    letter-spacing: 0.04em;
    display: flex;
    justify-content: center;
    gap: 11px;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    transition: 0.3s;
    font-weight: 600;
  }

  .cv_file_name {
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    color: var(--cl-blue);
    line-height: 1.66;
    text-align: center;
    @mixin max-line-length 2;
  }
  &:hover {
    color: var(--cl-purple);
  }
}

.circle-btn{
  display: flex;
  flex-shrink: 0;
  width: ac(48px, 38px);
  height: ac(48px, 38px);
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: var(--cl-purple);
  cursor: pointer;
  background: var(--cl-lilac);
  border: 1px solid var(--cl-lilac);
  border-radius: 50%;
  transition: 0.3s;
  i{
    display: inline-block;
  }
  &-service{
    i{
      transform: rotate(45deg);
    }
  }
  &:hover{
    border-color: var(--cl-white);
    background: transparent;
    color: var(--cl-white);
  }
}