.get-in-touch-section {
  position: relative;
  z-index: 50;
  overflow: visible;

  &__wrap {
    position: relative;
  }

  &__container {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-45.7%) translateZ(0);
    border-radius: ac(20px, 12px);
    clip-path: inset(0 0 0 0 round ac(20px, 12px));
    padding: ac(60px, 40px) ac(60px, 24px);
    z-index: 10;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    max-width: 1278px;
    margin-left: ac(-4px, 0px);
    overflow: hidden;
  }

  &__title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 35.92%;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(40px, 23px);
    }
  }

  &__text {
    margin-top: ac(104px, 60px);
    width: 40.41%;
    margin-left: ac(-5px, -20px);

    &.small-size {
      @mixin max-sm {
        p, li {
          line-height: 24px;
        }
      }
    }
  }

  &__buttons {
    @mixin full-btn;
  }

  &__bg-image {
    position: absolute;
    inset: 0;
    z-index: -2;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--mob {
      @mixin sm {
        display: none;
      }
    }

    &--desk {
      @mixin max-sm {
        display: none;
      }
    }
  }

  &__bg-decor-container {
    @mixin aspect-ratio-block 497, 499;
    width: ac(497px, 378px);
    position: absolute;
    mix-blend-mode: soft-light;
    bottom: 23px;
    right: ac(-102px, -162px);
  }

  &__bg-decor {
    @mixin aspect-ratio 497, 499;
    width: 100%;
  }

  @mixin media 1100 {
    .get-in-touch-section {
      &__title-container {
        width: 42%;
      }

      &__text {
        margin-left: 10px;
        width: 55%;
      }
    }
  }

  @mixin max-md {
    .get-in-touch-section {
      &__container {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__title-container {
        width: 100%;
      }

      &__title {
        max-width: 300px;
      }

      &__text {
        margin-top: 32px;
        margin-left: 0;
        width: 100%;
      }
    }
  }

  @mixin max-sm {
    .get-in-touch-section {
      &__bg-decor-container {
        bottom: -161px;
        right: -160px;
        transform: rotate(180deg) translateY(-50%);
      }

      &__container {
        min-height: 440px;
        left: -12px;
        right: -12px;
        transform: translateY(-50%) translateZ(0);
      }
    }
  }

  @mixin max-xs {
    .get-in-touch-section {
      &__container {
        left: -6px;
        right: -6px;
      }
    }
  }
}