.hiring-solutions-page {
    background: var(--cl-turquoise);

    .first-section {
        &__wrap {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            padding-bottom: ac(100px, 60px);
            gap: 35px;
            height: auto;
            min-height: calc(100 * var(--vh, 1vh));
            @media (min-height: 1080px) {
                min-height: 1080px;
            }
        }

        &__title {
            h1 {
                span {
                    &:nth-of-type(2) {

                        @media (min-width: 1100px){
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }

    .hero-thumbnail-wrapper {
        flex-shrink: 0;
        width: 412px;
        max-width: 37%;
        position: relative;
        align-self: center;

        &::before {
            content: '';
            display: block;
            padding-top: 100%;
        }

        .thumb-shadow {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: left bottom;
            mix-blend-mode: overlay;
        }
    }

    .hero-thumbnail {
        width: 81.4%;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;

        &::before {
            content: '';
            display: block;
            padding-top: 100%;
        }

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.3s;
        }
    }

    .footer__wrap {
        padding-top: ac(120px, 60px) !important;
    }
}

.lds-section-wrapper {
    --mb: 200px;
    margin-bottom: calc(var(--mb) * -1);
    padding-bottom: var(--mb);
    position: relative;
    overflow: hidden;
    background: linear-gradient(180deg, var(--cl-white), var(--cl-turquoise));

    .section-figures {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        .fig {
            position: absolute;
            width: fit-content;
            opacity: 0.4;
            left: 50% !important;
            top: 50% !important;
            transform: translate(-50%, -50%);

            img {
                height: auto;
                width: 100%;
            }

            &.lines {
                width: perc(2263);
                max-width: 2263px;
                top: 67.2% !important;
                left: 55% !important;
                transform: translate(-50%, -50%) !important;
            }

            &.cube {
                width: perc(1035);
                max-width: 1035px;
                opacity: 0.2;
                left: 59% !important;
                top: 50.6% !important;
            }

            &.cubes {
                width: perc(1032);
                min-width: 350px;
                max-width: 1032px;
                left: 0 !important;
                top: 0 !important;
                transform: translate(-24.5%, -14%) !important;
                opacity: 0.2;
            }
        }
    }
}

.lds-section {
    padding: ac(120px, 60px) 0 ac(100px, 25px);

    .title-animation {
        font-size: ac(120px, 36px);
        color: var(--cl-blue);
        width: 100%;
        margin-bottom: ac(40px, 30px);
        text-align: right;

        span {
            &:last-of-type {
                display: inline-block;
                text-align: left;
            }
        }
    }

    .section-description {
        font-size: ac(30px, 20px);
        line-height: 1.5;
        max-width: 955px;
    }

    .btn {
        margin-top: ac(50px, 35px);
    }
}

.areas-section {
    padding: ac(102px, 35px) 0 ac(117px, 40px);

    .title-wr {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: ac(52px, 40px);
        flex-wrap: wrap;
        gap: 25px;

        .btn {
            margin-bottom: ac(13px, 0px);
        }
    }

    .title-animation {
        h2 {
            line-height: 0.98;
        }

        span {
            &:nth-of-type(2) {
                margin-left: ac(68px, 25px);
            }
        }
    }
}

.areas-card {
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    width: ac(630px, 500px);
    max-width: 100%;

    &-thumbnail-wr {
        flex-shrink: 0;
        width: 44.92%;
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            display: block;
            padding-top: 125.08%;
        }
    }

    .thumbnail-layer {
        overflow: hidden;
        border-radius: 20px 0 0 20px;
        @media (max-width: 550px) {
            border-radius: 20px 20px 0 0;
        }
    }

    &-thumbnail {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.3s;
        }

        &-bg-layer {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 2;
            mix-blend-mode: overlay;
            transform: translate(30%, 37%);
        }
    }

    &-text {
        flex: 1;
        background: var(--cl-white);
        padding: ac(32px, 20px);
        display: flex;
        flex-direction: column;
    }

    &-title {
        font-size: ac(30px, 22px);
        font-weight: 800;
        line-height: 1.5;
        color: var(--cl-blue);
        margin-bottom: 16px;
    }

    &-description {
        margin-bottom: 16px;
        width: 100%;
        font-size: 16px;
        ul{
            list-style: none;
            display: flex;
            gap: 5px;
            flex-wrap: wrap;
            padding: 0;
            margin: 0;
            li{
                font: 12px var(--font-main) !important;
                line-height: 1.5;
                padding: 5px 8px!important;
                border-radius: 5px;
                border: 1px solid var(--cl-lilac);
                color: #082655;
                background: var(--cl-white);
                text-align: center;
                font-weight: 400;
                margin: 0!important;

            }
        }
    }

    .btn {
        margin-top: auto;
        font-size: 16px;
        font-weight: 500;
        border-color: var(--cl-navy);
        min-width: 155px;

        .btn__text {
            font-size: inherit;
        }
    }
}

.areas-swiper-wr {
    .swiper {
        overflow: visible;

        &-slide {
            width: fit-content;
            max-width: 100%;
            height: auto;
            display: flex;
        }
    }

    .slider-btn {
        margin-top: 50px;
    }
}

.solutions-section {
    padding-top: 1px;
    position: relative;
    margin-bottom: 17px;

    &-blue {
        margin-bottom: 0;
        padding-bottom: 0;

        .features-wrapper {
            margin-bottom: 0;
            padding-bottom: 0;
            background: var(--cl-blue);
        }

        .features {
            padding-bottom: 80px;
        }

        .benefits-block {
            .benefits-grid-item-icon {
                background: url('../images/ben-icon-border-blue.svg') center /
          contain no-repeat;
            }

            .benefits-thumbnail-img {
                width: ac(469px, 150px);
                opacity: 1;
            }
        }
    }
}

.features-wrapper {
    --mt: ac(200px, 100px);
    --mb: 250px;
    border-radius: 20px 20px 0 0;
    margin-top: calc(var(--mt) * -1);
    padding-top: var(--mt);
    margin-bottom: calc(var(--mb) * -1);
    padding-bottom: var(--mb);

    background: linear-gradient(103.02deg, #061344 -1.78%, #8900ea 119.69%);

    .cont {
        padding-top: 60px;
        padding-bottom: ac(80px, 40px);
    }
}

.features {
    display: flex;
    gap: 30px;
    justify-content: space-between;

    &-col {
        flex-shrink: 0;
        width: 522px;
        max-width: 42%;

        .btn {
            margin-top: 32px;
        }
    }

    .title-animation {
        mix-blend-mode: normal;

        h2 {
            font-size: ac(80px, 32px);
            line-height: 1.05;
            margin-bottom: 32px;
            color: var(--cl-white);
            font-weight: 700;
        }
    }

    &-grid {
        flex: 1;
        max-width: 678px;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 24px;
        align-self: flex-start;
    }

    .section-description {
        color: var(--cl-white);
    }

    &-card {
        color: var(--cl-white);

        &-title {
            line-height: 1.5;
            font-size: ac(30px, 20px);
            font-weight: 800;
            padding-bottom: 8px;
            position: relative;
            margin-bottom: 9px;

            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 48px;
                height: 1px;
                background: var(--cl-white);
                mix-blend-mode: overlay;
            }
        }

        &-description {
            font-size: 16px;

            --line-count: 10;
            --fz: 16px;
            --line-height: 1.5;
            line-height: var(--line-height);
            font-size: var(--fz);
            max-height: calc(var(--fz) * var(--line-height) * var(--line-count));

            &.simplebar-scrollable-y {
                /* padding-right: 15px;*/
            }

            .simplebar-scrollbar::before {
            }

            .simplebar-track.simplebar-vertical {
            }
        }
    }
}

.benefits-block {
    border-radius: 12px;
    position: relative;
    transform: translate(0);
    display: flex;
    gap: 40px;
    justify-content: space-between;
    overflow: hidden;
    padding: ac(60px, 30px) ac(60px, 20px);
    border: 1px solid var(--cl-purple);

    clip-path: inset(0 0 0 0 round 12px);

    &-bg {
        position: absolute;
        inset: 0;
        z-index: -1;

        /*&::before {
                  content: '';
                  display: block;
                  position: absolute;
                  inset: 0;
                  z-index: 1;
                  background: var(--cl-white);
                  opacity: 0.7;
                }*/

        picture {
            img {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .benefits-thumbnail-img {
        position: absolute;
        top: auto;
        left: -21px;
        bottom: 0;
        width: ac(447px, 150px);
        height: auto;
        mix-blend-mode: overlay;
        opacity: 0.6;
        object-fit: contain;

        &.top {
            z-index: 2;
        }
    }

    &-col {
        flex-shrink: 0;
        max-width: 484px;
    }

    .benefits-title {
        font-size: ac(44px, 24px);
        font-weight: 700;
        line-height: 1.2727;
        padding-bottom: 275px;
        color: var(--cl-blue);
    }

    .benefits-grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: ac(40px, 25px) ac(58px, 25px);
        flex: 1;
        max-width: ac(755px, 630px, 1024, 1440);
        align-self: flex-start;
        @media (min-width: 1440px) {
            max-width: ac(1210px, 755px, 1440, 1920);
        }

        &-item {
            display: flex;
            align-items: center;
            gap: ac(20px, 10px);

            &-icon {
                flex-shrink: 0;
                width: ac(84px, 64px);
                height: ac(84px, 64px);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 7px 7px 0;
                background: url('../images/ben-icon-border.svg') no-repeat center / contain;

                img {
                    max-width: 75%;
                    max-height: 75%;
                }
            }

            &-text {
                flex: 1;
                font-size: ac(24px, 18px);
                font-weight: 700;
                line-height: 1.3333;
                font-family: var(--font-second);
            }
        }
    }

    &-reverse {
        flex-direction: row-reverse;

        .benefits-title {
            text-align: right;
        }

        .benefits-thumbnail-img {
            left: auto;
            right: ac(59px, 20px);
        }
    }
}

.counts-section {
    padding: ac(118px, 50px) 0 ac(120px, 40px);
    position: relative;
    overflow: hidden;
    background: linear-gradient(103.02deg, #061344 -1.78%, #8900ea 119.69%);

    .bg-layer {
        position: absolute;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
        width: 105%;
        height: 105%;
        mix-blend-mode: luminosity;
        opacity: 0.3;
        z-index: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .cont-second {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        position: relative;
        z-index: 2;
    }

    &-text {
        flex: 1;
        max-width: 620px;
        color: var(--cl-white);

        .btn {
            margin-top: 40px;
        }
    }

    .title-animation {
        margin-bottom: 8px;

        h2 {
            color: var(--cl-white);
        }
    }

    .section-description {
        font-size: ac(30px, 20px);
    }
}

.safari-support {
    .counts-section {
        .bg-layer {
            mix-blend-mode: plus-lighter;
        }
    }
}

.counts-grid {
    width: 580px;
    flex-shrink: 0;
    max-width: 50%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
}

.counts-box {
    position: relative;

    &::before {
        content: '';
        display: block;
        padding-top: 100%;
    }

    &-border {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &-inner {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        color: var(--cl-white);
        gap: 16px;
        font-family: var(--font-second);
        padding: 0 22px 22px 0;
    }

    .count-up {
        font-size: ac(60px, 35px);
        font-weight: 800;
        line-height: 1.035;
        mix-blend-mode: overlay;
    }

    &-text {
        width: 100%;
        --line-count: 2;
        --fz: ac(24px, 18px);
        --line-height: 1.3333;
        font-weight: 700;
        line-height: var(--line-height);
        font-size: var(--fz);
        max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
        padding: 0 10px;

        &.simplebar-scrollable-y {
            /* padding-right: 15px;*/
        }

        .simplebar-scrollbar::before {
            /*background-color: var(--orange);
                                    color: var(--orange);
                                    opacity: 1!important;
                                    left: 1px;
                                    right: 1px;*/
        }

        .simplebar-track.simplebar-vertical {
            /*  background: rgba(255,255,255, 0.2)!important;
                                      opacity: 1!important;
                                      width: 5px;
                                      border-radius: 5px;
                                      right: -8px;*/
        }
    }
}

.ts-section.ts-section-hiring {
    .section-bg img {
        object-position: center bottom;
    }

    .cont {
        background: none;
    }
}

@media (max-width: 1150px) {
    .solutions-section-reverse {
        .features {
            flex-direction: column-reverse;
        }
    }

    .benefits-block {
        flex-direction: column;
        gap: 35px;

        &-col {
            max-width: 100%;
            width: 100%;
        }

        .benefits-title {
            padding-bottom: 0;
            text-align: center;
        }

        .benefits-grid {
            max-width: 100%;
            width: 100%;
            padding-bottom: 140px;
        }
    }

    .benefits-block .benefits-thumbnail {
        width: 150px;
        bottom: -24px;
    }
}

@media (max-width: 992px) {
    .features {
        flex-direction: column;
        gap: 45px;

        &-col,
        &-grid {
            max-width: 100%;
            width: 100%;
        }
    }

    .counts-section {
        .cont-second {
            flex-direction: column-reverse;
            gap: 40px;
        }

        &-text {
            max-width: 100%;
        }
    }

    .counts-grid {
        max-width: 100%;
    }
}

@media (max-width: 700px) {
    .benefits-block .benefits-grid {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 650px) {
    .hiring-solutions-page {
        .first-section__wrap {
            flex-direction: column-reverse;
            justify-content: center;
        }

        .hero-thumbnail-wrapper {
            max-width: 90%;
            width: 280px;
        }
    }

    .features-grid {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 550px) {
    .areas-card {
        width: 375px;
        flex-direction: column;

        &-thumbnail-wr {
            max-width: 100%;
            width: 100%;

            &::before {
                padding-top: 62%;
            }
        }

        &-thumbnail {
            img {
                object-position: center 19%;
            }

            &-bg-layer {
                transform: translate(30%, 58%);
            }
        }
    }

    .counts-grid {
        grid-template-columns: minmax(0, 250px);
        justify-content: center;
    }
}
