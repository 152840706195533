.service-card {
  position: relative;
  padding: ac(31px, 22px) ac(25px, 20px) ac(31px, 22px) ac(30px, 20px);
  border-radius: ac(20px, 15px);
  clip-path: inset(0 0 0 0 round ac(20px, 15px));
  background: var(--cl-white);
  overflow: hidden;
  transform: translateZ(0);
  color: var(--cl-navy);
  transition: 0.3s;
  border: 1px solid var(--cl-lilac);
  min-height: ac(260px, 240px);
  display: flex;



  &__bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
    opacity: 0;
    transition: 0.3s;

    img{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
    }
  }

  &__header{
    display: flex;
    gap: 20px;
    padding-bottom: 13px;
    margin-bottom: auto;
    justify-content: space-between;
    width: 100%;
    align-items: center;

  }

  &__decor {
    @mixin aspect-ratio 1, 1;
    width: 100%;
    mix-blend-mode: overlay;
    position: absolute;
    min-width: ac(663px, 483px);
    left: ac(-99px, -72px);
    top: ac(-120px, -87px);
    transition: opacity .3s ease;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 2;
  }

  &__title {
    font-size: ac(26px, 22px);
    font-weight: 600;
    line-height: 1.075;
    transition: 0.3s;
    color: var(--cl-navy) !important;
  }

  &__text {
    width: calc(100% + 10px);
    padding-right: 10px;
    max-height: 173px;
    max-width: revert;

    .simplebar-track.simplebar-vertical {
      background: var(--cl-turquoise);

      .simplebar-scrollbar {
        background: var(--cl-navy);
      }
    }

    &.smaller-size {
      p, li {
        color: var(--cl-navy);
        font-weight: 300;
        padding-bottom: 1px;

        @mixin max-sm {
          line-height: 1.5;
        }
      }
    }

  }

  ul{
    list-style: none;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    li{
      font: 12px var(--font-main) !important;
      line-height: 1.5;
      padding: 5px 8px!important;
      border-radius: 5px;
      border: 1px solid var(--cl-lilac);
      color: #082655;
      background: var(--cl-white);
      text-align: center;
      font-weight: 400;
      margin: 0!important;

    }
  }

  &:hover {
    color: var(--cl-white);
    border: 1px solid #A080C3;
   .service-card{
     &__bg{
       opacity: 1;
     }
     &__title{
       color: var(--cl-white) !important;
     }
   }

    ul{
      li{
        background: rgba(255,255,255, 0.12);
        border: 1px solid #E1E8FF66;
        color: var(--cl-white);
      }
    }
    .circle-btn{
      background: transparent;
      color: var(--cl-white);
      border-color: var(--cl-white);
    }
  }
}