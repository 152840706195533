@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Anybody:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --cl-black: #000000;
  --cl-black-rgb: 0 0 0;

  --cl-navy: #061344;

  --cl-blue: #5500EC;

  --cl-purple: #8900EA;

  --cl-turquoise: #1bd4ed;

  --cl-lilac: #E1E8FF;

  --cl-primary: #ec612a;
  --cl-primary-rgb: 236 97 42;

  --cl-white: #ffffff;
  --cl-white-rgb: 255 255 255;

  --font-main: "Poppins", sans-serif;
  --font-second: "Anybody", sans-serif;

  --input-border-color: rgba(6,19,68,0.15);

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1200;

  --vh: calc(100vh / 100);
  --dvh: calc(100vh / 100);
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
/* @custom-media --mobile-menu-start-point (max-width: 1200px); */

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
/* @custom-media --mobile-menu-end-point (min-width: 1201px); */


@define-mixin mobile-menu-start-point {
  @media(max-width: 1280px) {
    @mixin-content;
  }
}
@define-mixin mobile-menu-end-point {
  @media (min-width: 1281px) {
    @mixin-content;
  }
}
