.stats-section {
  margin-bottom: -40px;
  position: relative;
  z-index: 10;
  overflow: hidden;

  .cont {
    max-width: 1400px;
  }

  &__bg {
    overflow: hidden;
    border-radius: 10px;
    position: relative;
  }

  &__bg-image {
    position: absolute;
    inset: 0;
    z-index: -2;

    &--mob {
      @mixin sm {
        display: none;
      }

      img {
        object-position: top;
        min-height: 748px;
      }
    }

    &--desk {
      @mixin max-sm {
        display: none;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__bg-decor {
    @mixin aspect-ratio 1, 1;
    width: ac(940px, 514px);
    position: absolute;
    bottom: ac(-503px, -264px);
    left: ac(-246px, -249px);
    mix-blend-mode: soft-light;
    opacity: .6;
    z-index: -1;
  }

  &__wrap {
    padding-top: ac(60px, 40px);
    padding-bottom: ac(98px, 40px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 1280px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

  }

  &__content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: ac(120px, 30px);
    }
  }

  &__text {
    margin-top: ac(20px, 0px);
    width: 40.78%;

    &.small-size {
      p, li {
        font-size: ac(16px, 18px);
        line-height: 1.5;

        @mixin max-sm {
          line-height: 24px;
        }
      }
    }
  }

  &__title {
    width: 57.11%;
    mix-blend-mode: overlay;
    margin-left: auto;

    h2 {
      color: var(--cl-white);

      span {
        &:first-child {
          margin-left: ac(76px, 26px);
        }
      }
    }

    /*&:not(:last-child) {
      margin-bottom: ac(27px, 32px);
    }*/
  }

  &__list {
    width: 100%;
    max-width: 1221px;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: ac(50px, 16px);
    grid-row-gap: ac(40px, 16px);
  }

  /*&__slider {
    overflow: visible;
    margin-left: 0;
    width: 100%;
    max-width: 1221px;

    &:not(.swiper-initialized) {
      display: none;
    }
  }*/

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    &:first-of-type{
      .count-up-suffix{
        font-size: 70%;
        padding-left: 5px;
        transform: translateY(9%);
      }
    }
  }

  /*&__item-count-container {
    mix-blend-mode: overlay;
  }*/

  &__item-count {
    position: relative;
    font-size: ac(80px, 40px);
    font-weight: 700;
    line-height: ac(84px, 48px);
    font-family: var(--font-second);
    color: var(--cl-white);
    mix-blend-mode: overlay;
    flex-shrink: 0;
    display: flex;
    align-items: flex-end;

    &:not(:last-child) {
      margin-bottom: ac(26px, 15px);
    }
  }

  &__item-title {
    font-family: var(--font-main);
    font-weight: 400;
    font-size: ac(30px, 24px);
    line-height: normal;

    &:not(:last-child) {
      margin-bottom: ac(15px, 6px);
    }

    @mixin max-sm {
      font-family: var(--font-second);
      font-weight: 500;
      line-height: 28px;
    }
  }

  @mixin media 901 {
    .stats-section {
      &__content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__text {
        width: 100%;
        margin-bottom: 32px;
      }

      &__title {
        width: 100%;
        max-width: ac(740px, 264px);
        margin-left: 0;
      }

      &__list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
  }

  @mixin max-sm {
    .stats-section {
      &__wrap {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }

  @media (max-width: 551px){
    .stats-section {
      &__title {
        margin-left: auto;
        margin-right: auto;
      }

      &__list {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }

      &__item {
        flex-direction: column;
        gap: 25px;
        align-items: center;
        text-align: center;
      }

      &__item-count {
min-width: revert;
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 360px){
    .stats-section {
      &__wrap {
        padding-left: 10px;
        padding-right: 10px;
      }

      &__item-title {
        font-size: 22px;
      }
      &__item-count{
      }
    }
  }
  & + .roles-section{
    --mt:ac(332px, 96px);
    padding-top: var(--mt);
    margin-top: calc(var(--mt) * -1);
    .cont{
      padding-top: ac(201px, 96px);
    }
  }
}