.services-section {
  position: relative;
  z-index: 10;
  overflow: hidden;

  .cont {
    max-width: 1400px;
  }

  &__bg {
    overflow: hidden;
    border-radius: 10px;
    position: relative;
  }

  &__bg-image {
    @mixin aspect-ratio 2671.18, 1624.68;
    position: absolute;
    width: ac(2672px, 3069px);
    top: ac(-7px, 0px);
    transform: translateX(-50%);
    z-index: -2;
    left: 44%;

  }

  &__bg-decor {
    @mixin aspect-ratio 1425.06, 1624.68;
    width: ac(1425px, 486px);
    position: absolute;
    top: ac(-258px, -106px);
    right: ac(-919px, -425px);
    mix-blend-mode: soft-light;
    z-index: -1;
  }

  &__wrap {
    padding-top: ac(120px, 38px);
    padding-bottom: ac(118px, 40px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: ac(69px, 32px);
    }
  }

  &__title-container {
    width: 78.29%;
    position: relative;
    padding-left: ac(414px, 200px);
    padding-bottom: ac(111px, 80px);
  }

  &__title {
    position: absolute;
    left: 0;
    bottom: ac(-3px, 0px);
    mix-blend-mode: overlay;

    h2 {
      color: var(--cl-white);
      line-height: 0.94;
      span{
        line-height: inherit;
        &:not(:first-of-type){
        /*  transform: translateY(-6%);*/
        }
        &:nth-of-type(2) {
          margin-left: ac(66px, 28px);
        }
      }
    }

    /*&:not(:last-child) {
      margin-bottom: ac(27px, 32px);
    }*/
  }

  &__text {
    width: 100%;
  }

  &__buttons {
    justify-content: flex-end;
    padding-bottom: ac(16px, 20px);
    padding-right: ac(16px, 0px);
    @mixin full-btn;
  }

  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: ac(20px, 12px);
    grid-row-gap: ac(20px, 12px);
  }

  @mixin media 901 {
    .services-section {
      &__title-container {
        padding-left: 0;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__title {
        position: relative;
        left: auto;
        bottom: auto;
        margin-bottom: ac(24px, 16px);
      }
    }
  }

  @mixin media 851 {
    .services-section {
      &__wrap {
        padding-left: 12px;
        padding-right: 12px;
      }

      &__content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__title-container {
        margin-bottom: 32px;
        width: 100%;
      }

      &__buttons {
        width: 100%;
        justify-content: flex-start;
      }

      &__list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
  }

  @mixin media 551 {
    .services-section {
      &__list {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }

      &__buttons {
        padding-bottom: 0;
      }

      &__bg-decor {
        opacity: .8;
      }
    }
  }

  @mixin max-xs {
    .services-section {
      &__wrap {
        padding-left: 6px;
        padding-right: 6px;
      }
    }
  }

  + .testimonials-section {
    @mixin media 551 {
      margin-top: -701px;
      padding-top: 701px;
    }
  }

  @media (max-width: 374px){
    .services-section__title h2{
      font-size: 30px;
    }
    }
}