@font-face {
  font-family: 'icomoon';
  src:  url('../../assets/fonts/icomoon.eot?9hbyjz');
  src:  url('../../assets/fonts/icomoon.eot?9hbyjz#iefix') format('embedded-opentype'),
  url('../../assets/fonts/icomoon.ttf?9hbyjz') format('truetype'),
  url('../../assets/fonts/icomoon.woff?9hbyjz') format('woff'),
  url('../../assets/fonts/icomoon.svg?9hbyjz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-minus:before {
  content: "\e913";
}
.icon-plus:before {
  content: "\e914";
}
.icon-phone:before {
  content: "\e910";
}
.icon-email:before {
  content: "\e911";
}
.icon-search:before {
  content: "\e912";
}
.icon-upload:before {
  content: "\e90f";
}
.icon-chevron-right:before {
  content: "\e90d";
}
.icon-chevron-left:before {
  content: "\e90e";
}
.icon-arrow-right-long:before {
  content: "\e90c";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-next:before {
  content: "\e902";
}
.icon-arrow-prev:before {
  content: "\e903";
}
.icon-arrow-right:before {
  content: "\e904";
}
.icon-arrow-up:before {
  content: "\e905";
}
.icon-check:before {
  content: "\e906";
}
.icon-close:before {
  content: "\e907";
}
.icon-facebook:before {
  content: "\e908";
}
.icon-instagram:before {
  content: "\e909";
}
.icon-linkedin:before {
  content: "\e90a";
}
.icon-twitter:before {
  content: "\e90b";
}
