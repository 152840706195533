.areas-page{
    .first-section{
        &__wrap{
            min-height: ac(700px, 470px);
            height: auto;
            padding-bottom: ac(129px, 130px);
            padding-top: ac(140px, 170px);
        }
        .fig-wrapper {
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(56.9%, -48.3%);
            width: perc(1164);
            max-width: 1228px;

            &-top {
                transform: translate(62.4%, -51.1%);
                img {
                    position: relative;

                }
            }
        }

        .fig {
            img {
                width: 100%;
                height: auto;
                position: relative;
                z-index: 0;
                mix-blend-mode: screen;
            }
        }
        & + .areas-tabs-section{
            .areas-tabs-wrapper{
                margin-top: ac(-120px, -90px);
                z-index: 11;
                position: relative;
            }
        }

        &__title{
            span{
                &:last-of-type{
                    /*margin-left: ac(71px, 25px);*/
                    margin-left: 0 !important;
                }
            }
        }
    }
    .footer__wrap{
        padding-top: ac(118px, 60px) !important;
    }
    .testimonials-section__bg img{
        object-fit: cover;
        object-position: center bottom;
    }
}

.areas-tab-switchers-item{
    border: 1px solid rgba(6,19,68,0.15);
    border-radius: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    padding: 5px 12px 5px 24px;
    min-height: 64px;
    cursor: pointer;
    transition: 0.3s;
    font-weight: 700;
    font-family: var(--font-second);
    font-size: ac(24px, 18px);
    line-height: 1.3333;
    &-icon{
        position: relative;
        width: fit-content;
        display: block;
        &::before{
            content: '';
            display:block;
            width: 40px;
            height: 40px;
            background: url("../images/tab.svg") no-repeat center / contain;
            transition: 0.1s;
        }
        &::after{
            content: '';
            display:block;
            width: 100%;
            height: 100%;
            background: url("../images/tab-active.svg") no-repeat center / contain;
            transition: 0.1s;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
        }

        &.active{
            &::before{
                opacity: 0;
            }
            &::after{
                opacity: 1;
            }
        }
    }

    &.active{
        background: var(--cl-white);

        .areas-tab-switchers-item-icon{
            &::before{
                opacity: 0;
            }
            &::after{
                opacity: 1;
            }
        }
    }
    &:hover{
        border-color: var(--cl-purple);
    }
}

.areas-tabs-wrapper{
    display: flex;
    justify-content: space-between;
    position: relative;
    transform: translateX(0);
    padding-left: ac(60px, 20px);
    filter:  drop-shadow(0 4px 12px rgba(0,0,0,0.04));
    margin-left: ac(20px, 0px);
    gap: ac(60px, 20px);

    .section-bg{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        overflow: hidden;
        background: var(--cl-lilac);
        border-radius: 20px 0 0 20px;
        pointer-events: none;

        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center bottom;
            transform: none;
            mix-blend-mode: screen;
        }
    }
}

.areas-tab-switchers{
    padding: ac(120px, 50px) 0;
    max-width: 502px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;

}

.areas-tab-swipers-wr{
    flex: 1;
    width: 778px;
    max-width: 62%;
    margin-right: ac(-20px, -24px);
    padding-top: ac(281px, 150px);
    padding-bottom: ac(30px, 20px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

}

.swiper-tab-bg{
    position: absolute;
    top: 0;
    right: 0;
    width: 90%;
    height: 100%;
    z-index: 0;
    background: var(--cl-navy);
    .swiper-slide{
        position: relative;
        height: auto;
    }
}

.areas-tab-info-bg-card{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;

    img{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
    }
}

.swiper-tab-content{
    position: relative;
    z-index: 1;
    max-width: 90%;
    margin: 0;
    .swiper-slide{
        .areas-tab-info-item{
            transform: translateY(20px);
            transition: 0.5s;
        }
        &-active{
            .areas-tab-info-item{
                transform: none;
            }
        }
    }
}

.areas-tab-info-item{
    position: relative;
    padding: ac(40px, 30px)ac(40px, 20px);
    border-radius: 20px;
    overflow: hidden;
    background: linear-gradient(103.02deg, rgba(6, 19, 68, 0.2) -1.78%, rgba(137, 0, 234, 0.2) 119.69%), url("../images/areas-tab-info-item-bg-el.jpeg") no-repeat center / cover;

    &-inner{
        position: relative;
    }
    &-title{
        color: var(--cl-white);
        font-size: ac(60px, 25px);
        font-weight: 800;
        line-height: 1.035;
        margin-bottom: 25px;
        mix-blend-mode: overlay;
        font-family: var(--font-second);
    }

    &-content{
        --line-count:7;
        --fz: ac(20px, 18px);
        --line-height: 1.5;
        line-height: var(--line-height);
        font-size: var(--fz);
        /*max-height: calc(var(--fz) * var(--line-height) * var(--line-count) + 4px);*/
        color: var(--cl-white);
        font-weight: 300;
        max-height: 206px;
        ul{
            list-style: none;
            display: flex;
            gap: 5px;
            flex-wrap: wrap;
            padding: 0;
            margin: 0;
            li{
                font: 12px var(--font-main) !important;
                line-height: 1.5;
                padding: 5px 8px!important;
                border-radius: 5px;
                border: 1px solid var(--cl-lilac);
                color: var(--cl-white);
                background: rgba(255,255,255,0.12);
                text-align: center;
                font-weight: 400;
                margin: 0!important;

            }
        }

    }

    .btn{
        margin-top: 32px;
    }
}

.wlc-section.wlc-section-areas{
    overflow: hidden;
    padding: ac(120px, 50px) 0;
    .section-bg{
        pointer-events: none;
        img{
            object-position: left top;
        }
    }
    .title-animation{
        text-align: left;
        h2{

        }
        span{
            &:nth-of-type(2){
                margin-left: ac(47px, 25px);
            }
        }
    }
    .wlc-section-thumbnail{
     max-width: 522px;
    }
    .wlc-section-text{
     max-width: 702px;
    }
    .bg-layer-wr{

        overflow: visible;
    }
    .bg-layer{
        width: 100%;
        transform: translate(-86.8%, -29.4%) !important;
    }
}
.roles-section.roles-section-areas{
    padding:ac(120px, 50px) 0;

}

.testimonials-section.testimonials-section-areas{
    margin: 0;
    padding-top: 11px;
    padding-bottom: ac(118px, 40px);
    overflow: visible;
    .bg-layer{
        position: absolute;
        right: 0;
        width: perc(230);
        max-width: 230px;
        opacity: 0;
        transform: translate(50%, -50%) scale(0.5);
        transition: 0.5s;
        min-width: 100px;
        &.animated{
            opacity: 1;
            transform: translate(50%, -55%)scale(1);
        }
    }
}

@media (max-width: 750px) {
    .areas-tabs-wrapper{
        flex-direction: column;
        width: calc(100% + 24px);
    }
    .areas-tab-switchers{
        max-width: 100%;
        width: 100%;
        padding-bottom: 40px;
        padding-right: 24px;
    }
    .areas-tab-swipers-wr{
        width: calc(100% + 40px);
        margin-left: -20px;
        max-width: revert;
        padding-top: ac(300px, 220, 375, 750);
    }
    .swiper-tab-bg{
        width: calc(100% - 20px);
        border-radius: 20px 0 0 20px;
    }
}

@media (max-width: 450px) {
    .swiper-tab-content{
        width: 100%;
    }
}