h1 {
  font-size: ac(140px, 42px);
  font-weight: 800;
  line-height: 1.0354;
  font-family: var(--font-second);

  @mixin media 374 {
    font-size: 40px;
  }

  @mixin media 330 {
    font-size: 38px;
  }
}

h2 {
  font-size: ac(100px, 36px);
  font-weight: 800;
  line-height: 1.03846;
  font-family: var(--font-second);

  &.big-size {
    font-size: ac(130px, 42px);

    @mixin media 374 {
      font-size: 40px;
    }

    @mixin media 330 {
      font-size: 38px;
    }
  }

  &.small-size {
    font-size: ac(60px, 36px);
  }
}

h3 {
  font-size: ac(44px, 26px);
  font-weight: 700;
  line-height: normal;
}

h4 {
  font-size: ac(30px, 24px);
  line-height: normal;
  font-weight: 800;
}

h5 {
  font-size: ac(28px, 20px);
  font-weight: 500;
  line-height: 1.5;
}

h6 {
  font-size: ac(24px, 18px);
  font-weight: 500;

  line-height: 2;
}

p {

  @mixin max-sm {
    line-height: 1.33;
  }
}

li {
  font-size: ac(20px, 18px);
  font-weight: 400;
  line-height: 1.25;

  @mixin max-sm {
    line-height: 1.33;
  }
}

a {
  display: inline-block;
  font-size: ac(16px, 14px);
  font-weight: 400;
  line-height: 1.62;

  @mixin max-sm {
    line-height: 1.57;
  }
}

.content-element {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  }

  &.first-big-paragraph {
    p {
      &:first-child {
        font-family: var(--font-second);
        font-weight: 500;
        font-size: ac(24px, 22px);
        line-height: 1.42;

        &:not(:last-child) {
          margin-bottom: ac(25px, 24px);
        }

        @mixin max-sm {
          line-height: 1.2;
        }
      }
    }
  }



  h4 {
    color: var(--cl-medium-grey);
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  ol,
  ul {
    li {
      list-style-position: inside;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  li,
  p {
    /*Not Design*/
    &:not(:last-child) {
      margin-bottom: ac(5px, 8px);
    }
  }

  &.smaller-size {
    p, li {
      font-size: ac(16px, 14px);
      font-weight: 400;
      line-height: normal;
    }
  }

  &.small-size {
    p, li {
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
    }
  }

  &.big-size {
    /*p {
      font-size: ac(24px, 22px);
      line-height: 1.42;
    }*/
  }

  .simplebar-track.simplebar-vertical {
    width: ac(3px, 2px);
    background: var(--cl-turquoise);
    border-radius: 0;

    .simplebar-scrollbar {
      background: var(--cl-navy);
      border-radius: 0;

      &:before {
        content: none;
      }
    }
  }

  a {
    font: inherit;
    transition: all .3s ease;
    text-decoration: underline;

    &:hover {
      color: var(--cl-blue) !important;
    }
  }
}

.dark-bg {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: var(--cl-white);
  }
}
