.about-us-page {
  .first-section {
    &__subtitle {
      text-align: left;
    }

    &__wrap {
      padding-bottom: ac(190px, 0px);
      padding-bottom: ac(190px, 0px);
    }

    &__title h1 {
      span {
        &:nth-of-type(2) {
          margin-left: 0;
        }
      }
    }

    .fig-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(45.4%, -36.2%);
      width: perc(1167);
      max-width: 1228px;

      &-top {
        transform: translate(50.8%, -38.9%);

        img {
          position: relative;
        }
      }
    }

    .fig {
      img {
        width: 100%;
        height: auto;
        position: relative;
        z-index: 0;
        mix-blend-mode: screen;
      }
    }
  }
}

.wlc-section-wrapper {
  background: var(--cl-lilac);
}

.wlc-section {
  position: relative;
  background: var(--cl-lilac);
  padding: ac(104px, 58px) 0 80px;

  .btn {
    margin-top: 32px;
    margin-top: 32px;
  }

  .bg-fig {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
    width: perc(230);
    max-width: 230px;
    min-width: 100px;
    z-index: 120;

    img {
      width: 100%;
      height: auto;
    }
  }

  .bg-layer-wr {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-62%, -48%);
    z-index: 0;
    overflow: hidden;

    .bg-layer {
      position: absolute;
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
    }

    img {
      max-width: revert;
      width: perc(2263);
    }
  }

  .cont-second {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }

  &-thumbnail {
    max-width: 592px;
    flex: 1;
    border-radius: 20px;
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-text {
    flex: 1;
    max-width: 632px;
    font-weight: 300;
  }

  .section-description {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .title-animation {
    text-align: right;
    color: var(--cl-blue);
    margin-bottom: 16px;

    i {
      display: inline-block;
      margin-bottom: 16px;
      font-size: 60.19%;
      font-style: normal;
    }
  }
}

.values-section {
  position: relative;
  z-index: 2;
.section-bg{
  z-index: -1;
  &::before{
    content: '';
    display:block;
    position: absolute;
    inset: 0;
    z-index: 1;
    opacity: 0.9;
    background: var(--cl-turquoise);
  }
}
  .title-animation {
    mix-blend-mode: normal;
    color: #47ffff;
    margin-bottom: ac(53px, 40px);

    span {
      display: inline-block;
      line-height: 1;

      &:nth-of-type(2) {
        margin-left: ac(67px, 30px);
        transform: translateY(-6%);
      }
    }
  }

  .cont {
    border-radius: 12px;
    padding: ac(120px, 50px) 0;
    transform: translate(0);
    overflow: hidden;
    &-second{
      @media (max-width: 1100px){
        padding: 0 15px;
      }
    }
  }


  .section-figures-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    mix-blend-mode: overlay;
    overflow: hidden;
    pointer-events: none;
  }

  .section-figures {
    width: perc(1176);
    max-width: 1176px;
    position: absolute;
    top: 32.8%;
    left: 80.5%;
    transform: translate(-50%, -50%);

    &::before {
      content: '';
      display: block;
      padding-top: 96.96%;
    }

    .fig {
      position: absolute;

      img {
        width: 100%;
        height: auto;
      }

      &.fig-1 {
        width: 1016px;
        top: 0;
        right: 0;
        max-width: 89%;
      }

      &.fig-2 {
        width: 897px;
        bottom: -28%;
        left: 0.4%;
        max-width: 79%;
      }

      &.fig-3 {
        width: 505px;
        left: 1.7%;
        top: 31.2%;
        max-width: 44%;
      }
    }
  }

  .bg-layer {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    width: perc(230);
    min-width: 100px;
  }
}

.values-list {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: ac(20px, 10px);
  position: relative;
  z-index: 2;
}

.values-card {
  position: relative;
  transform: translate(0);
  color: var(--cl-white);
  padding: ac(44px, 20px) ac(44px, 20px) ac(32px, 20px);
  display: flex;
  flex-direction: column;
  gap: ac(28px, 16px);

  &-bg {
    border-radius: 20px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
    pointer-events: none;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-header {
    width: 100%;
    display: flex;
    gap: ac(28px, 20px);
    align-items: center;

    h3 {
      font-size: ac(34px, 24px);
      line-height: 1.2944;
      font-weight: 600;
      font-family: var(--font-second);
      margin: 0;

      @media (max-width: 490px) {
        font-size: 22px;
      }

      @media (max-width: 425px) {
        font-size: 20px;
      }
    }
  }

  &-icon {
    width: ac(80px, 60px);
    height: ac(80px, 60px);
    object-fit: contain;
    object-position: center;
    flex-shrink: 0;

    @media (max-width: 425px) {
      width: 50px;
      height: 50px;
    }
  }

  &-description {
    width: 100%;
    line-height: 1.5;
    padding-left: ac(21px, 10px);
  }
}

.timeline-section {
  --mt: 258px;
  --mb: ac(450px, 250px);
  padding-top: var(--mt);
  margin-top: calc(var(--mt) * -1);
  transform: translate(0);
  padding-bottom: var(--mb);
  margin-bottom: calc(var(--mb) * -1);
  background: linear-gradient(
    270deg,
    var(--cl-turquoise),
    var(--cl-purple),
    var(--cl-blue)
  );
  overflow: hidden;

  .bg-layer {
    position: absolute;
    right: 0;
    bottom: ac(111px, 50px);
    max-width: 56%;
    z-index: -1;

    img {
      width: 100%;
    }
  }

  .cont {
    padding-top: ac(120px, 70px);
    padding-bottom: ac(120px, 50px);
  }

  .title-animation {
    font-size: ac(60px, 32px);
    color: var(--cl-white);
  }

  .title-wr {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    padding-bottom: 60px;

    .slider-btn {
      margin: 0;
    }
  }
}

.timeline-swiper-wr {
  .swiper {
    width: 630px;
    max-width: 100%;
    margin: 0;
    overflow: visible;

    &-slide {
      position: relative;

      &.swiper-slide-active {
        .timeline-date-card {
          &-date {
            color: var(--cl-white);
          }

          &-title {
            position: absolute;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s;
          }
        }
      }
    }
  }

  .swiper-timeline-date {
    mix-blend-mode: overlay;
  }
}

.timeline-date-card {
  color: var(--cl-white);

  &-date {
    font-size: ac(140px, 60px);
    font-weight: 800;
    display: flex;
    gap: 20px;
    margin-bottom: 12px;
    line-height: 1.03;
    color: rgba(255, 255, 255, 0.05);
    -webkit-text-stroke: 1px #ffffff;
    transition: color 0.5s;

    span {
      display: block;
      flex: 1;
      height: 1px;
      background: #000;
      background: var(--cl-white);
      align-self: center;
    }
  }

  &-title {
    font-size: ac(34px, 24px);
    font-weight: 800;
    line-height: 1.5;
    transition: opacity 0.3s 0.5s;
  }
}

.timeline-card {
  position: relative;
  width: 522px;
  max-width: 100%;

  &-thumbnail-wr {
    position: relative;
    margin-bottom: 24px;

    .layer {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 1;
      mix-blend-mode: screen;
      border-radius: 25px;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &-thumbnail {
    position: relative;
    overflow: hidden;
    border-radius: 25px;
    z-index: 0;

    &::before {
      content: '';
      display: block;
      padding-top: 56.32%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-title {
    color: var(--cl-white);
    font-size: ac(34px, 24px);
    font-weight: 800;
    margin-bottom: 8px;
    line-height: 1.5;
  }

  &-description {
    color: var(--cl-white);
    font-weight: 400;
    width: 100%;

    --line-count:7;
    --fz: 18px;
    --line-height: 1.5;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
    p{
      line-height: inherit;
    }

    &.simplebar-scrollable-y{
        padding-right: 15px;
    }
    .simplebar-scrollbar::before {
      background: var(--cl-white);
    }

    .simplebar-track.simplebar-vertical{

    }
  }
}

.team-section {
  .bg-layer {
    width: 410px;
    max-width: 30%;
    min-width: 250px;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-50%, -18.4%);
    z-index: -1;
  }

  .cont {
    padding: ac(121px, 35px) 0 273px;
    position: relative;
    border-radius: 12px;
    background: var(--cl-white);
    transform: translate(0);
    overflow: hidden;
  }

  .title-wr {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: flex-end;
    padding-bottom: ac(52px, 40px);
    padding-right: 7px;
    @media (max-width: 450px) {
      flex-direction: column-reverse;
    }

    .slider-btn {
      margin: 0 0 6px;
    }
  }

  .title-animation {
    text-align: right;
    color: var(--cl-blue);
    margin: 0;

    span {
      &:nth-of-type(2) {
        margin-right: ac(72px, 30px);
        transform: translateY(-6%);
      }
    }
  }
}

.team-swiper-wr {
  .swiper {
    overflow: visible;

    &-slide {
      width: fit-content;
      max-width: 100%;
    }
  }
}

.teammate-card {
  width: ac(377px, 330px);
  max-width: 100%;
  position: relative;
  overflow: hidden;
  transform: translate(0);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 32px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;

  &-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-thumbnail-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 34px;
  }

  &-thumbnail {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    z-index: 0;
    width: 100%;
    flex-shrink: 0;

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-layer {
      position: absolute;
      inset: 0;
      z-index: 2;
      opacity: 0.3;

      img {
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  &-logo {
    max-width: 107px;
    max-height: 25px;
    width: 100%;
    margin-bottom: 15px;


    img {
      width: auto;
      height: auto;
    }
  }

  &-name {
    font-size: ac(34px, 24px);
    font-weight: 800;
    line-height: 1.5;
    margin-bottom: 8px;
    margin-right: -10px;
  }

  &-position {
    font-family: var(--font-second);
    font-size: ac(24px, 20px);
    font-weight: 500;
    line-height: 1.3333;
    margin-bottom: 40px;
  }
  &-soc{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    color: var(--cl-blue);

    font-weight: 400;
    line-height: 1.5;
    a{
      color: var(--blue);
      &:not([class]){
        text-decoration: underline;
        font-size: ac(18px, 16px);
      }
      &:hover{
        color: var(--cl-purple);
      }
    }
  }

  &-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    padding-right: 3px;
    margin-bottom: -2px;

    i {
      font-size: 14px;
    }
    &:hover{
      color: var(--cl-blue);
    }
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  }
}

.get-in-touch-section.get-in-touch-section-about-us {
  .get-in-touch-section__container {
    margin-left: auto;
    margin-right: auto;
  }

  .get-in-touch-section__bg-decor-container {
    width: 607px;
    height: auto;
    top: 46% !important;
    left: 39% !important;
    bottom: auto;
    transform: translateY(-84%) !important;

    &::before {
      display: none;
    }

    img {
      position: relative;
      width: 100%;
      height: auto;
    }
  }
}

.about-section{
  --pb: 155px;
  padding-bottom: var(--pb);
 /* margin-bottom: calc(-1 * var(--pb));*/

  .cont{
    position: relative;
    background: var(--cl-white);
    border-radius: 12px;
    padding-top: ac(120px, 40px, 375);
    padding-bottom: 120px;
    .bg-layer{
      position: absolute;
      &:nth-of-type(1){
        left: 0;
        top: 0;
        width: ac(205px, 80px);
      }
      &:nth-of-type(2){
        width: ac(410px, 207px);
        bottom: 0;
        left: 0;
        transform: translate(-50%, 50%);
      }
    }
  }
  &-wrapper{
    display: flex;
    gap: ac(56px, 30px);
  }
  &-thumbnail-wr{
    align-self: center;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    width: ac(602px, 280px);
    border-radius: 50%;
    max-width: 90%;
    &::before{
        content: '';
        display:block;
        padding-top: 100%;
    }
    .layer{
      opacity: 0.3;
      z-index: 1;
    }
    img{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
    }
  }
  &-text{
    flex-grow: 1;
  }
  &-logo{
    width: 104px;
    margin-bottom: 23px;
    img{
      width: 100%;
    }
  }
  .title{
    font-size: ac(60px, 35px);
    color: var(--cl-blue);
    margin-bottom: 8px;
    line-height: 1.035;
  }
  &-position{
    font-size: ac(34px, 22px);
    font-weight: 800;
    line-height: 1.5;
    margin-bottom: ac(24px, 15px);
  }
  &-description{
    font-weight: 300;
    font-size: ac(20px, 18px);
    line-height: 1.5;
    p{
      margin-bottom: 16px;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
  }
  .btn{
    font-size: 16px;
    font-weight: 500;
    border: 1px solid var(--cl-navy);
    margin-top: 24px;
    min-width: 154px;
  }
}

@media (max-width: 992px) {
  .about-section {
    &-wrapper{
      flex-direction: column;
    }
  }
}

@media (max-width: 768px) {
  .wlc-section {
    .cont-second {
      flex-direction: column-reverse;
      gap: 40px;
    }

    &-thumbnail {
      width: 400px;
      max-width: 100% !important;
    }

    &-text {
      width: 100%;
      max-width: revert;
    }
  }
}

@media (max-width: 650px) {
  .values-list {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 551px) {
  .about-us-page {
    .first-section__wrap {
      justify-content: center;
    }
  }
}
