.specialism-section {
  padding-top: ac(40px, 20px);
  padding-bottom: ac(40px, 20px);
  position: relative;
  z-index: 10;

  &__color-bg {
    background: var(--cl-beige);
    position: relative;

    .cont-main {
      position: relative;
    }
  }

  &__wrap {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: ac(50px, 32px);
    padding-bottom: ac(54px, 24px);
  }

  &__title {
    width: 100%;
    padding-right: 51.21%;

    &:not(:last-child) {
      margin-bottom: ac(50px, 26px);
    }

    @mixin max-md {
      padding-right: 0;
    }
  }

  &__slider-buttons {
    @mixin md {
      display: none;
    }

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__list {
    width: calc(51.21% - ac(105px, 24px));
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: ac(647px, 547px);

    @mixin sm {
      padding-right: 10px;
    }

    @mixin max-md {
      display: none;
    }

    li {
      font-size: ac(25px, 20px);
      line-height: 1.4;
      transition: all .3s ease;
      padding-left: 0;
      font-weight: 400;
      color: var(--cl-black);
      position: relative;
      cursor: pointer;
      font-family: var(--font-main);

      &:before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 1px;
        background: var(--cl-leaf);
        transition: width .3s ease;
        content: "";
        width: 0;
      }

      &:not(:last-child) {
        margin-bottom: ac(20px, 16px);
      }

      &.active {
        padding-left: ac(38px, 24px);
        font-size: ac(29px, 22px);
        font-weight: 600;
        color: var(--cl-leaf);
        letter-spacing: -0.5px;

        &:before {
          width: ac(30px, 18px);
        }

        &:not(:last-child) {
          margin-bottom: ac(15px, 14px);
        }

        @mixin media 1439 {
          font-size: ac(26px, 22px);
        }

        @mixin media 1100 {
          font-size: ac(22px, 20px);
        }

        @mixin max-md {
          font-size: 22px;
        }
      }

      &:not(.active) {
        &:hover {
          color: var(--cl-mint);
        }
      }
    }
  }

  &__slider-container {
    /*padding-top: ac(227px, 190px);*/
    width: 48.84%;
    padding-right: ac(6px, 20px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @mixin media 951 {
      padding-right: 0;
      padding-left: 20px;
    }

    @mixin max-md {
      width: 100%;
      padding-left: 0;
    }

    @mixin max-sm {
      padding-top: 100px;
      position: relative;
      min-height: 544px;
      padding-bottom: 24px;
    }
  }

  &__slider-image {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 48.95%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right bottom;
    }

    @mixin max-sm {
      left: calc((-100vw - -87.20vw) / 2);
      right: calc((-100vw - -87.20vw) / 2);
      width: auto;
      
      img {
        object-position: top left;
      }
    }

    &:before {
      position: absolute;
      inset: 0;
      z-index: 1;
      content: "";
      background: var(--cl-beige);
      mix-blend-mode: color;
    }
  }

  &__slider {
    margin-top: auto;
    overflow: hidden;
    margin-left: ac(-105px, -24px);

    @mixin media 951 {
      margin-left: 0;
      margin-right: -10px;
    }

    @mixin max-sm {
      overflow: visible;
      max-width: 297px;
    }

    @mixin media 390 {
      max-width: 279px;
    }
  }

  &__item {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ac(50px, 22px) ac(50px, 20px) ac(38px, 19px);
    min-height: ac(413px, 272px);
    background: var(--cl-mint);
    height: auto;
  }

  &__item-title {
    font-size: ac(50px, 22px);
    line-height: 1.2;
    font-weight: 600;

    @mixin max-sm {
      line-height: 1.4;
    }

    &:not(:last-child) {
      margin-bottom: ac(20px, 8px);
    }
  }

  &__item-text {
    &.big-size {
      p {
        font-family: var(--font-second);
        font-weight: 500;
        font-size: ac(24px, 14px);
        line-height: 1.42;
      }

      &[data-simplebar] {
        max-height: calc((ac(24px, 14px) * 1.42) * 6);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(20px, 8px);
    }

    &[data-simplebar] {
      width: calc(100% + 10px);
      padding-right: 10px;

      .simplebar-track.simplebar-vertical {
        width: ac(4px, 3px);
        background: var(--cl-background);
        border-radius: 0;
        .simplebar-scrollbar {
          background: var(--cl-leaf);
          border-radius: 0;
          &:before {
            content: none;
          }
        }
      }
    }
  }

  &__item-links {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @mixin max-sm {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 44px;

    .specialism-section__wrap {
      padding-bottom: 0;
    }
  }
}