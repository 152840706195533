.text-section {
  position: relative;
  z-index: 10;

  &__wrap {
    display: flex;
  }

  &__title {
    h2 {
      br {
        + span {
          margin-left: ac(72px, 32px);
        }
      }
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__buttons {

  }

  &.home-page {
    margin-top: ac(50px, 40px);
    padding-top: ac(80px, 40px);
    padding-bottom: ac(36px, 16px);

    @mixin max-sm {
      margin-top: 0;
    }

    .text-section {
      &__wrap {
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        grid-row-gap: ac(30px, 32px);
      }

      &__title-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      &__title {
        width: 100%;
        max-width: ac(720px, 310px);
        text-align: left;
      }

      &__text {
        margin-top: ac(0px, 8px);
        width: 48.79%;

        @mixin max-lg {
          width: 52%;
        }

        @mixin max-md {
          width: 100%;
        }
      }

      &__buttons {
        padding-top: ac(30px, 0px);
        width: 47.99%;

        @mixin max-lg {
          width: 46%;
        }

        @mixin max-md {
          width: 100%;
        }
      }
    }
  }
}