.meta-select {
    --font-main: var(--font-main, 'sans-serif');
    --fz: ac(16px, 18px);
    --border-color: transparent;
    --line-height: 1.5;

    --list-border-color: var(--input-border-color);
    --text-color: var(--cl-blue);
    --hover-item-color: var(--cl-turquoise);
    --hover-item-bg: transparent;
    --font-weight: 500;

    --select-border-radius: 8px;
    --select-padding: 22px 43px 22px 23px;

    --selct-bg-color: var(--cl-white, #fff);

    --dropdown-bg-color: var(--cl-white, #fff);
    --dropdown-border-color: var(--input-border-color);
    --dropdown-item-padding: 22px 43px 22px 23px;
    --chevron-color: var(--cl-navy );

    --list-item-selected-color: var(--cl-navy);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &.meta-select-sort{
        --select-padding: 12px 24px 12px 0;
        --dropdown-item-padding: 12px 24px 12px 16px;
        --text-color: var(--cl-blue);

        border: 1px solid var(--input-border-color);
        border-radius: 8px;
        background: var(--cl-white);
        padding-left: 16px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        width: fit-content;
        gap: 4px;
        position: relative;
        .primary-label{
            flex-shrink: 0;
            opacity: 0.7;
            width: fit-content;
            font-weight: 500;
        }



        .choices{
            position: static!important;
            height: auto;
           &__inner{
               width: 214px;
               position: relative;
               min-height: 46px;
           }
            &__list--dropdown{
                width: 100%;
                left: 0!important;
            }
            &[data-type*="select-one"]:after{
                right: 16px!important;
            }
        }
    }



    label {
        font-family: var(--font-main);
        font-size: ac(16px, 14px);
        line-height: 1.2;
        font-weight: 700;
        color: var(--black);
        cursor: pointer;
        width: 100%;
    }

    select {
        opacity: 0;
        height: ac(50px, 48px);
    }

    .choices {
        width: 100%;
        margin-bottom: 0;
        overflow: visible;
        border-radius: 5px;

        height: 100%;

        &__inner {

            height: 100%;
            border: 1px solid var(--border-color);
            padding: 0 !important;
            border-radius: var(--select-border-radius);
            outline: none;
            transition: box-shadow 0.3s ease, border-color 0.3s ease,
            border-radius 0.3s ease, caret-color 0.3s ease, color 0.3s ease;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @mixin max-line-length-one;
            width: 100%;
            cursor: pointer;
            font-size: ac(16px, 14px);
            line-height: var(--line-height);
            font-weight: 500;
            font-family: var(--font-main);
            color: var(--black);
            box-shadow: none;



            &.error {
                border-color: var(--cl-red);
                box-shadow: 2px 2px 5px 0px rgba(var(--cl-red-rgb) / 0.3);
            }
        }

        &__list--single{
            color: var(--text-color);
        }

        &.is-open {
            &:not(.is-flipped) {
                .choices__inner {
                    border-radius: var(--select-border-radius);
                }

                .choices__list--dropdown,
                .choices__list[aria-expanded] {
                    border-top: none;
                    margin-top: 0;
                    border-radius: var(--select-border-radius);
                }
            }

            &.is-flipped {
                .choices__inner {
                    border-radius: 0 0 6px 6px;
                }

                .choices__list--dropdown,
                .choices__list[aria-expanded] {
                    margin-bottom: 0;
                    border-bottom: none;
                    border-radius: 6px 6px 0 0;
                    border-color: var(--dropdown-border-color);
                }
            }
            .choices__list--dropdown{
                border-color: var(--dropdown-border-color);
            }
        }

        &__item {
            @mixin max-line-length-one;
        }

        &__placeholder {
            color: var(--navy);
            opacity: 1;
        }

        &__input {
            display: none !important;
        }

        &__list {
            padding: 0;

            .choices__item {
                font-size: var(--fz);
                font-family: var(--font-main);
                font-weight: var(--font-weight);
                line-height: 1.04;
                padding: var(--select-padding);
                transition: 0.3s color;

                &.is-selected {
                    color: var(--list-item-selected-color);
                }
            }


            &--multiple {
                color: var(--navy);

                .choices__item--selectable {
                    display: none;
                }

                + .choices__input {
                    display: none;
                }
            }

            &--dropdown {
                border: 1px solid var(--dropdown-border-color);
                background-color: var(--dropdown-bg-color);

                .choices__item {
                    color: var(--text-color);
                    border-bottom: 1px solid var(--list-border-color);
                    padding: var(--dropdown-item-padding);

                    &:first-of-type {
                        display: none;
                    }
                }
            }

            &--single {
                background: var(--selct-bg-color);
            }
        }

        &[data-type*='select-one'],
        &[data-type*='select-multiple'] {
            cursor: pointer;

            &:after {
                border: none;
                content: '';
                display: block;
                width: 14.4px;
                height: 8px;
                margin-top: -4px;
                pointer-events: none;
                position: absolute;
                right: 18px;
                top: 50%;
                transition: all 0.15s ease-in-out;
                background-image: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.234315 0.234315C0.546734 -0.0781049 1.05327 -0.0781049 1.36569 0.234315L7.2 6.06863L13.0343 0.234315C13.3467 -0.0781049 13.8533 -0.0781049 14.1657 0.234315C14.4781 0.546734 14.4781 1.05327 14.1657 1.36569L7.76569 7.76569C7.45327 8.07811 6.94673 8.07811 6.63431 7.76569L0.234315 1.36569C-0.0781048 1.05327 -0.0781048 0.546734 0.234315 0.234315Z' fill='%23061344'/%3E%3C/svg%3E%0A");
            }
        }

        &.is-open {
            &:after {
                transform: rotateX(180deg);
            }
        }

        &__list--dropdown .choices__item--selectable.is-highlighted,
        &__list[aria-expanded] .choices__item--selectable.is-highlighted {
            background-color: var(--hover-item-bg);

            &:not(.is-selected) {
                color: var(--hover-item-color);
            }
        }

        &__list--dropdown,
        .choices__list[aria-expanded] {

            z-index: 20;
            border-radius: 6px;

        }

        &[data-type*='select-one'] .choices__input {
            margin: 2px;
            max-width: calc(100% - 4px);
            border: 2px solid var(--cl-grey);
            border-radius: 8px;
            background: var(--transparent);
            color: var(--cl-dark-grey);
            font-size: ac(16px, 14px);
            line-height: 1.2;
            font-weight: 500;
            font-family: var(--font-main);
        }

        &[data-type*='select-multiple'] .choices__inner {
            cursor: pointer;
        }
    }

    &__list {
        display: none;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        max-width: 100%;
        grid-column-gap: 10px;
        grid-row-gap: 8px;

        &.is-visible {
            margin-top: 10px;
            display: flex;
        }
    }

    &__list-item {
        position: relative;
        font-size: ac(16px, 14px);
        line-height: 1.2;
        font-weight: 500;
        font-family: var(--font-main);
        padding-right: 14px;
        cursor: default;
        color: var(--navy);

        &:after {
            content: '';
            cursor: pointer;
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 10px;
            height: 10px;
            transition: background-color 0.25s ease, opacity 0.25s ease,
            transform 0.25s ease;
            opacity: 0.5;
            background: var(--navy);
            clip-path: polygon(
                    20% 0%,
                    0% 20%,
                    30% 50%,
                    0% 80%,
                    20% 100%,
                    50% 70%,
                    80% 100%,
                    100% 80%,
                    70% 50%,
                    100% 20%,
                    80% 0%,
                    50% 30%
            );
        }

        &:hover {
            &:after {
                opacity: 1;
                transform: translateY(-50%) scale(1.15);
            }
        }
    }

    &__help,
    &__error {
        margin-top: 5px;
        font-family: var(--font-main);
        font-size: ac(14px, 12px);
        line-height: 1.2;
        font-weight: 600;
    }

    &__help {
        color: var(--cl-dark-grey);
    }

    &__error {
        color: var(--cl-red);
    }
}

@media (max-width: 650px) {
    .meta-select.meta-select-sort{
        width: 100%;

        .choices{
            flex: 1;
        }
    }
}

/*enf of meta select*/



