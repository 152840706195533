.news-page {
  .section-bg {
    z-index: -1;
  }

  .first-section {
    z-index: 0;
    background: none;
    overflow: visible;
    &__bg{
      inset: revert;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: visible;
      z-index: -1;
      img{
        height: auto;
      }
    }

    &__wrap {
      height: auto;
      min-height: 0;
      padding-top: 211px;
      padding-bottom: ac(59px, 30px);
    }

    &__title {
      mix-blend-mode: normal;
      margin-bottom: ac(46px, 36px);
      span{
        &:last-of-type{
          /*margin-left: ac(66px, 20px);*/
          margin-left: 0 !important;
        }
      }

      h1 {
        color: var(--cl-navy);
        font-size: ac(100px, 32px);
      }
    }
  }

  .footer__wrap{
    padding-top: ac(120px, 60px) !important;
  }
  .page-pagination{
    padding-top: ac(65px, 40px);
  }
}

.news-form {
  display: flex;
  gap: 20px;
  width: 738px;
  max-width: 100%;
  align-items: center;

  .input-wr {
    flex: 1;

    input {
      @mixin transparent-bg-input var(--cl-navy);
      width: 100%;

    }
  }

  .btn {
    padding-left: 27px;
    padding-right: 27px;
    gap: 9px;
    .btn__icon {
      color: var(--cl-blue);
      transform: none !important;
      transition: color 0.3s;
      font-size: 20px;
      margin-right: 5px;
    }
  }
}

.new-results {
  padding: 40px 0 80px;
  z-index: 1;
  position: relative;

  &-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: ac(29.4px, 15px)ac(20px, 15px);
  }
}
.news-swiper-wr{
  .swiper{
    &-slide{
      display: flex;
      height: auto;
    }
  }
}

.news-card {
  width: 100%;
   display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0 0 20px 20px;
  cursor: pointer;

  &-thumbnail-wr {
    position: relative;
  }

  &-thumbnail {
    position: relative;
    overflow: hidden;
    border-radius: 20px 20px 0 0;

    &::before {
      content: '';
      display: block;
      padding-top: 66.58%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
      max-width: revert;
    }

    &-overlays {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 20px 20px 0 0;

      picture {
        overflow: hidden;
        img {
          opacity: 0;
          transition: opacity 0.3s;
          will-change: opacity;
        }
        &:nth-of-type(1) {
          img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            mix-blend-mode: screen;
            border-radius: 20px 20px 0 0;
            overflow: hidden;
          }
        }

        &:nth-of-type(2) {
          img {
            position: absolute;
            right: 0;
            top: 0;
            width: perc(159, 413);
            mix-blend-mode: color-burn;
          }
        }
      }
    }
  }

  &-inner{
    background: var(--cl-white);
    padding: 20px ac(32px, 20px) ac(32px, 20px);
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  time{
    font-size: 16px;
    color: var(--cl-blue);
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 11px;
    span{
      color: #757575;
    }
  }
  &-title{
    @mixin max-line-length 2;
    font-size: ac(30px, 22px);
    line-height: 1.3300;
    font-weight: 800;
    margin-bottom: 16px;
  }
  &-description{
    @mixin max-line-length 4;
    margin-bottom: ac(38px, 28px);
    font-size: 16px;
    line-height: 1.5;
  }
  &-footer{
    margin-top: auto;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    color: var(--cl-blue);
    font-size: 18px;
    font-weight: 500;
    line-height: 1.44;
    padding-right: 5px;
    transition: 0.3s;
    i{
      font-size: 13px;
    }
    &:hover{
      color: var(--cl-turquoise);
    }
  }
  &:hover{
    .news-card-thumbnail{
      img{
        width: 105%;
        height: 105%;
      }
      &-overlays{
        picture {
          img{
            opacity: 1;
          }

          &:nth-of-type(1) {
            img {
              opacity: 0.6;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .news-page{
    .cont-second{
      width: 100%;
    }
  }

}

@media (max-width: 992px) {
  .new-results-grid{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .news-page{
    .first-section__title-container{
      max-width: 596px;
    }
  }
}
@media (max-width: 600px) {
  .new-results-grid{
    grid-template-columns: minmax(0, 375px);
    justify-content: center;
  }
  .news-form{
   flex-direction: column;
    .input-wr{
      width: 100%;
    }
    .btn{
      width: 100%;
    }
  }
}
