.bringing-section {
  padding-top: ac(263px, 128px);
  padding-bottom: ac(71px, 0px);
  position: relative;
  z-index: 2;
  max-width: 1440px;
  margin: ac(-262px, -128px) auto ac(-71px, 0px);

  &__bg {
    @mixin aspect-ratio 1508.5, 1352.9;
    position: absolute;
    width: 104.76%;
    min-width: 712px;
    top: 0;
    left: ac(-2px, -84px);
    z-index: 1;
  }

  &__wrap {
    padding-top: ac(147px, 56px);
    padding-bottom: ac(135px, 64px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 2;
  }

  &__title {
    width: 100%;
    max-width: ac(1158px, 340px);

    h2 {
      font-size: ac(130px, 42px);
      color: var(--cl-blue);

      span:first-child {
        margin-left: ac(238px, 76px);
      }

      /*br {
        + span {
          margin-left: ac(72px, 32px);
        }
      }*/
    }

    @mixin xl {
      opacity: .8 !important;
    }

    @mixin media 1100 {
      max-width: ac(1200px, 350px);
    }

    @mixin max-md {
      max-width: 340px;
    }

    &:not(:last-child) {
      margin-bottom: ac(29px, 32px);
    }
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__text {
    width: 49.14%;

    p, li {
      font-weight: 300;
    }

    @mixin media 1025 {
      width: 52%;
    }
  }

  &__buttons {
    width: 44%;
    justify-content: flex-end;
    padding-right: ac(111px, 0px);
    @mixin full-btn;
  }

  &__cubes {
    @mixin aspect-ratio-block 1024.1, 961.62;
    position: absolute;
    bottom: ac(-30px, 140px);
    right: ac(-160px, -285px);
    width: 91.97%;
    max-width: 1024px;
    min-width: 483px;
    z-index: -1;
    opacity: .4;
    /*mix-blend-mode: soft-light;*/
  }

  &__cube {
    &:nth-child(1) {
      @mixin aspect-ratio 611.9, 643.32;
      width: 59.75%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      @mixin aspect-ratio 657.8, 737.99;
      width: 64.23%;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
  }

  @mixin max-sm {
    .bringing-section {
      &__content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__text {
        width: 100%;
        margin-bottom: 32px;
      }

      &__buttons {
        width: 100%;
      }
    }
  }
}