/* ----------------------- Common desktop header ----------------------- */

.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 998;
  padding: ac(30px, 16px) 0;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &:before {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background-color: transparent;
    opacity: 0;
    transition: all 0.25s ease;
  }

  &.fixed {
    position: fixed;
  }

  /*&:not(.scrolled) {
    @mixin sm {
      margin-top: calc(ac(40px, 32px) + ac(52px, 40px));
    }

    @mixin max-sm {
      margin-top: ac(40px, 32px);
    }
  }*/

  &.scrolled {
    &:not(.active) {
      -webkit-backdrop-filter: blur(16px);
      backdrop-filter: blur(16px);
      padding: ac(22px, 14px) 0;

      &:before {
        opacity: 0.6;
        background-color: var(--cl-navy);
      }

      .logo {

      }

      /*.menu__link {
        color: var(--cl-white);

        &:hover {
          color: var(--cl-pink);
        }
      }

      .burger,
      .menu-toggle {
        svg path {
          &:not(.active) {
            stroke: var(--cl-white);

            &:hover {
              stroke: var(--cl-pink);
            }
          }
        }
      }*/
    }
  }

  /*&.white-type {
    &:not(.scrolled) {
      &:not(.active) {
        .logo {
          .color-change {
            path {
              fill: var(--cl-cream);
            }
          }
        }

        .menu__link {
          color: var(--cl-white);

          &:hover {
            color: var(--cl-pink);
          }
        }

        .burger,
        .menu-toggle {
          svg path {
            &:not(.active) {
              stroke: var(--cl-white);

              &:hover {
                stroke: var(--cl-pink);
              }
            }
          }
        }
      }
    }
  }*/

  .logo {
    height: 100%;
    width: ac(182px, 156px);
    flex-shrink: 0;
    transition: width 0.25s ease;
    z-index: 100;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    img,
    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
      transition: transform 0.25s ease;

      path {
        transition: all 0.3s ease;
        fill: var(--cl-white);
      }
    }

    &:hover {
      img,
      svg {
        transform: scale(1.06);

        path {
          fill: var(--cl-lilac)
        }
      }
    }

    @mixin max-xs {
      width: 180px;
    }
  }

  &__wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .burger {
    display: none;
  }

  &__navbar {
    display: flex;
    flex-grow: 1;

    .menu-list {
      display: flex;
      flex-grow: 1;
    }

    &__decor {
      display: none;
    }

    @mixin mobile-menu-end-point {
      max-width: calc(100% - 200px);
    }

    @mixin min-media 1400 {
      margin-bottom: -2px;
    }
  }

  &__socials {
    display: none;
  }

  &__navbar-decor {
    display: none;
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /*transition: opacity 0.2s ease;
    opacity: 0;*/

    @mixin mobile-menu-end-point {
      width: auto;
      flex: 1;
      align-items: center;

      .simplebar-content-wrapper, .simplebar-wrapper, .simplebar-mask {
        overflow: visible !important;
      }

      @mixin mobile-menu-end-point {
        .simplebar-content-wrapper {
          overflow: visible !important;
        }

        .simplebar-mask {
          overflow: visible !important;
        }

        .simplebar-wrapper {
          overflow: visible !important;
        }

        .simplebar-track {
          display: none !important;
        }

        .menu__list {
          overflow: visible !important;
        }
      }

      .simplebar-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        grid-column-gap: ac(20px, 14px);

        .menu-list {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: ac(32px, 20px);
        }

        &:after {
          display: none;
        }
      }
      .simplebar-track.simplebar-horizontal {
        display: none;
      }

      .menu {
        &__list {
          /*padding-left: 30px;
          padding-right: 30px;*/
          display: flex;
          align-items: center;
          justify-content: flex-end;
          /*grid-column-gap: ac(53px, 40px);*/
          background: var(--cl-navy);
          border-radius: 34px;
          overflow: hidden;
          position: relative;

          &:after {
            position: absolute;
            inset: 0;
            z-index: 5;
            border-radius: 34px;
            border: 2px solid var(--cl-black);
            pointer-events: none;
            content: "";
          }

          .flying-item {
            position: absolute;
            top: 0;
            bottom: 0;
            border-radius: 34px;
            background: rgba(27, 212, 237, 0.1);
            left: 0;
            transition: width .3s ease, margin-left .6s ease, opacity .3s ease;
            pointer-events: none;
            opacity: 0;
          }
        }
      }
    }

    &:not([data-simplebar='init']) {
      display: none;
    }
  }

  .menu__item {
    position: relative;
    .menu__link {
      padding: 0 ac(33px, 20px);
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.menu-dropdown {
      @mixin mobile-menu-end-point {
        padding-right: 0 !important;
      }

      position: relative;

      @mixin mobile-menu-start-point {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        .menu-dropdown__list {
          width: 100%;
        }
      }

      &.is-active {
        @media (max-width: 1280px) {
          .menu-dropdown__arrow {
            transform: translateY(0) rotate(-90deg) !important;
          }
        }
      }

      &:hover {
        @media (min-width: 1281px) {
          .menu-dropdown {
            &__arrow {
              transform: translateY(-30%) translateX(60%) rotate(-90deg);
            }

            &__list {
              max-height: 500px;
              opacity: 1;

              .menu-link {
                color: var(--cl-white) !important;

                &::before {
                  display: none;
                }

                &:hover {
                  color: var(--cl-purple) !important;
                }
              }
            }
          }
        }
      }
    }

  }

  .menu-dropdown {
    &__arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--cl-white);
      cursor: pointer;
      transition: all 0.3s ease;

      @mixin mobile-menu-end-point {
        position: absolute;
        top: 40%;
        transform: rotate(90deg) translateY(-50%);
        right: ac(20px, 16px);
        font-size: 12px;
      }

      @mixin mobile-menu-start-point {
        transform: rotate(90deg);
        font-size: 12px !important;
        margin-left: 6px;
      }
    }

    &__list {
      transition: max-height 0.3s ease, opacity 0.3s ease, margin-top 0.3s ease;
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      /*overflow-y: auto;
            scrollbar-width: thin;*/
      min-width: ac(163px, 158px);

      &::-webkit-scrollbar-button {
        display: none;
        background: red;
      }

      &::-webkit-scrollbar-button {
        height: 0;
        width: 0;
        opacity: 0;
      }

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 0;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--cl-purple);
        border-radius: 0;
      }

      @mixin mobile-menu-end-point {
        position: absolute;
        bottom: -2px;
        transform: translateY(100%);
        padding: 2px 14px;
        background: var(--cl-turquoise);
        border-radius: 20px;

        z-index: 999;

        .menu__link {
          padding: 0 !important;
          white-space: nowrap;
          color: var(--cl-navy) !important;

          &:hover {
            color: var(--cl-purple) !important;
          }
        }
      }

      /*.menu__link {
              white-space: nowrap;
            }*/

      @media (max-width: 639px) {
        align-items: center;
      }

      @mixin mobile-menu-start-point {
        align-items: center;

        .menu__link {
          width: 100% !important;
        }

        &.is-active {
          max-height: 800px;
          opacity: 1;
          margin-top: 0;
          grid-row-gap: 0;
        }
      }
    }

    @mixin mobile-menu-start-point {
      .menu__link {
        width: auto !important;
      }

      justify-content: center !important;
    }
  }

  .menu__link {
    position: relative;
    font-size: ac(16px, 14px);
    line-height: normal;
    font-weight: 500;
    color: var(--cl-white);
    transition: all 0.3s ease;
    cursor: pointer;
    font-family: var(--font-main);

    /*&:before {
      right: 0;
      left: auto;
      bottom: 0;
      width: 0;
      height: 1px;
      background: var(--cl-mint);
      opacity: 0;
      transition: width .3s ease, opacity .3s ease;
      content: "";
      position: absolute;
    }

    &:hover {
      color: var(--cl-mint);

      &:before {
        right: auto;
        left: 0;
        width: 100%;
        opacity: 1;
      }
    }*/
  }

  &__buttons {
    &.buttons {
      padding-left: ac(20px, 16px);

      @mixin max-sm {
        margin-top: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      .btn {
        height: 52px;
        border-color: rgba(225, 232, 255, 0.4);
        min-width: ac(154px, 140px);

        &__text {
          font-size: ac(16px, 18px);
          white-space: nowrap;
        }
      }

      @mixin mobile-menu-start-point {
        display: none;
      }

      @mixin max-sm {
        display: flex;
      }

      @mixin media 551 {
        .btn {
          width: 100%;
          height: 54px;
        }
      }
    }

    &--pad {
      display: none;

      @mixin mobile-menu-start-point {
        @mixin sm {
          display: flex !important;
          padding-right: 20px;

          .btn {
            height: ac(52px, 48px);
          }
        }
      }

      @mixin max-sm {
        display: none !important;
      }
    }
  }
}
.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  display: none;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@mixin mobile-menu-start-point {
  .header-close-wrapper{
    display: block;
  }
  .header:not(.header-mobile) {
    .burger,
    .menu-toggle {
      display: flex;
    }


    .logo {
      border: none;
    }

    .header__navbar-cont {
      display: flex;
      flex-direction: column;

      height: 100%;
      margin: 0 auto;
      max-width: 1280px;
      width: perc(1280);

      @mixin max-lg {
        width: 89.33%;
      }
    }

    .header__navbar {
      position: fixed;
      right: 0;
      top: 0;
      width: 40%;
      min-width: 320px;
      padding-top: ac(180px, 107px);
      background: linear-gradient(103.02deg, #061344 -1.78%, #8900EA 119.69%), #061344;
      padding-right: 40px;
      padding-left: 40px;
      padding-bottom: 40px;
      transition: transform 0.3s ease-out;
      overflow: hidden;
      flex-direction: column;
      height: auto;
      border-bottom-left-radius: 50px;

      @media screen and (max-height: 740px) {
        padding-top: 140px;
      }

      @mixin max-md {
        padding-right: calc((100vw - 87.20vw) / 2);
        padding-left: calc((100vw - 87.20vw) / 2);
      }

      @mixin max-sm {
        width: 100%;
        height: calc(var(--dvh, 1vh) * 100);
        border-bottom-left-radius: 0;
      }

      &.nav-slide-right {
        right: 0;
        left: auto;
        transform: translateX(100%);

        &.active {
          transform: translateX(0);
        }
      }

      .header__socials {
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;
        z-index: 1;
        margin-bottom: 40px;

        @mixin max-sm {
          justify-content: flex-start;
          padding-left: 4px;
        }
      }

      .menu {
        margin-top: auto;
        max-height: 100%;
        margin-bottom: 0;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0;

        align-items: flex-start;
        align-self: center;
        position: relative;
        z-index: 2;

        overflow-x: hidden;
        overflow-y: auto;

        .simplebar-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;

          .menu-list {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
          }
        }

        .simplebar-track.simplebar-vertical {
          width: 6px;
          background: var(--cl-navy);
          border-radius: 0;
          transform: none;

          .simplebar-scrollbar {
            background: var(--cl-blue);
            border-radius: 0;

            &:before {
              content: none;
            }
          }
        }

        &__list {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          border-radius: 36px;
          overflow: hidden;
          background: var(--cl-navy);
        }

        &__item {
          display: flex;
          justify-content: stretch;
          align-items: stretch;
          width: 100%;
          background: transparent;

          &.active {
            .menu__link {
              background-color: transparent !important;
              color: var(--cl-lilac);
            }
          }
        }

        &__link {
          font-family: var(--font-main);
          font-size: 20px;
          line-height: normal;
          text-align: center;
          border-radius: 36px;
          min-height: 72px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background-color .3s ease, color .3s ease;
          text-transform: capitalize;

          &:hover {
            &:not(.active) {
              color: var(--cl-lilac);
            }
          }
        }

        /*&__item.hidden {
          display: block;

          &.first-hidden {
            margin-top: 28px;

            @media screen and (max-height: 730px) {
              margin-top: 10px;
            }

            @media screen and (max-height: 640px) {
              margin-top: 0;
            }
          }

          .menu__link {
            position: relative;

            &:before {
              left: 0;
              bottom: 0;
              right: 0;
              height: 1px;
              width: 100%;
              opacity: 1;
              background: var(--cl-leaf);
              content: "";
              position: absolute;
              display: block;
            }
          }
        }*/
      }

      .header__buttons {
        padding-left: 0;
      }
    }

    .header__navbar-decor {
      display: flex;
      @mixin aspect-ratio 581.9, 546.67;
      position: absolute;
      top: -44px;
      right: 7px;
      width: 155.17%;
      mix-blend-mode: soft-light;
    }

    /*&.active {
      padding: 14px *;
      .logo {
        width: 215px;
      }
    }*/
  }
}

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
  background: var(--cl-white);
  position: relative;
  z-index: 100;
  clip-path: inset(0 0 0 0 round 50%);
  overflow: hidden;
  transition: background-color 0.3s ease;
  width: ac(52px, 48px);
  height: ac(52px, 48px);
  display: flex;
  justify-content: center;
  align-items: center;

  /*&:before {
    z-index: 1;
    inset: 0;
    content: '';
    position: absolute;
    background: var(--gradient);
    transition: opacity 0.3s ease;
    opacity: 0;
  }*/

  .burger-icon {
    cursor: pointer;
    transition: transform 400ms, background-color 0.3s ease;
    user-select: none;
    width: 43px !important;
    height: 43px !important;
    position: relative;
    z-index: 2;
    transform: scaleX(1.2);
  }

  &.active {
    /*&:before {
      opacity: 1;
    }*/

    .burger-icon {
      transform: rotate(45deg) scale(1.4);
    }

    .top {
      stroke-dashoffset: -68px;
    }

    .bottom {
      stroke-dashoffset: -68px;
    }

    .line {
      stroke: var(--cl-navy);
    }
  }

  .line {
    fill: none;
    transition: stroke 0.3s ease, stroke-dasharray 400ms,
    stroke-dashoffset 400ms, opacity 0.3s ease;
    stroke: var(--cl-navy);
    stroke-width: 3.5;
    stroke-linecap: round;
  }

  .top {
    stroke-dasharray: 40 121;
  }

  .bottom {
    stroke-dasharray: 40 121;
  }

  &:hover {
    .line {
      stroke: var(--cl-purple);
    }
  }

  &.active {
    &:hover {
      .line {
        stroke: var(--cl-purple);
      }
    }
  }

  @mixin max-xs {
    transform: scale(1.01);
  }
}


/* end of HEADER COMPONENTS */