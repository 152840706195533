.testimonial-card {
  position: relative;
  padding: ac(60px, 31px) ac(40px, 24px) ac(40px, 24px);
  border-radius: 20px;
  background: var(--cl-turquoise);
  transition: background-color .3s ease;
  overflow: hidden;
  cursor: default;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  height: auto;

  &__decor {
    @mixin aspect-ratio 878.2, 824.46;
    position: absolute;
    width: 100%;
    min-width: 878px;
    left: ac(-191px, -180px);
    top: 107px;
    opacity: .4;
    mix-blend-mode: overlay;
    transition: opacity .3s ease;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    width: 100%;
  }

  &__text {
    width: calc(100% + 10px);
    padding-right: 10px;
    max-height: ac(242px, 202px);
    a{
      display: inline-block;
      font-size: inherit;
      font-weight: inherit;
      text-decoration: underline;
      transition: 0.3s;
      &:hover{
        color: var(--cl-purple);
      }
    }

    p, li {
      color: var(--cl-navy);
      font-size: ac(20px, 16px);
      font-weight: 400;
      line-height: normal;
      padding-bottom: 1px;

      @mixin max-sm {
        line-height: 1.375;
      }
    }

    .simplebar-track.simplebar-vertical {
      background: var(--cl-lilac);
      transition: all .3s ease;

      .simplebar-scrollbar {
        background: var(--cl-navy);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(50px, 24px);
    }
  }

  &__author-content {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    grid-column-gap: ac(20px, 16px);
  }

  &__author-image {
    @mixin aspect-ratio 1, 1;
    border-radius: 50%;
    overflow: hidden;
    width: ac(106px, 72px);
    min-width: ac(106px, 72px);
  }

  &__author-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  &__author-title {
    font-family: var(--font-main);
    font-size: ac(34px, 18px);
    font-weight: 800;
    line-height: normal;
    color: var(--cl-navy) !important;
  }

  &__author-subtitle {
    font-family: var(--font-main);
    font-size: ac(18px, 16px);
    font-weight: 400;
    line-height: normal;
    color: var(--cl-navy) !important;
  }

  &:hover {
    background-color: var(--cl-lilac);

    .testimonial-card__text {
      .simplebar-track.simplebar-vertical {
        background-color: var(--cl-turquoise);
      }
    }
  }
}
