.roles-section {
  position: relative;
  z-index: 5;
  padding-top: ac(200px, 96px);
  padding-bottom: ac(235px, 277px, 375, 1440);
  background: var(--cl-turquoise);
  overflow: hidden;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
  }

  &__bg-decor {
    @mixin aspect-ratio 1411.46, 1325.08;
    position: absolute;
    width: ac(1411px, 576px);
    top: ac(-25px, 101px);
    left: ac(146px, -52px);
    z-index: -1;
    mix-blend-mode: soft-light;
    opacity: .70;

    @mixin max-xs {
      opacity: 1;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: ac(50px, 32px);
    }
  }

  &__title {
    width: calc(100% - 300px);

    h2 {
      color: var(--cl-navy);
    }
  }

  &__buttons {
    justify-content: flex-end;
    margin-bottom: ac(14px, 0px);
    @mixin full-btn;
  }

  &__slider-buttons {

    .slider-btn {
      margin-top: ac(48px, 32px);
    }
  }

  &__slider {
    overflow: visible;

    &:not(.swiper-initialized) {
      display: none;
    }
  }

  @mixin max-sm {
    .roles-section {
      &__content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__title {
        width: 100%;
        margin-bottom: 24px;
      }

      &__buttons {
        width: 100%;
      }
    }
  }
}