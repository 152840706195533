input,
textarea {
  border-radius: 0;
}

.meta-input,
.meta-upload-file {
  label {
    font-family: var(--font-main);
    font-size: ac(18px, 16px);
    line-height: normal;
    font-weight: 400;
    color: var(--cl-white);
    padding-bottom: ac(15px, 11px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      margin-left: 4px;
      display: inline-block;
      color: var(--cl-mint);
    }
  }
}

.meta-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  input,
  textarea {
    width: 100%;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: var(--font-second);
    color: var(--cl-black);
    border: 1px solid var(--cl-mint);
    border-radius: 0;
    outline: none;
    transition: box-shadow 0.25s ease, border-color 0.3s ease,
      caret-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
    padding: ac(20px, 16px) 20px;
    height: ac(64px, 56px);
    background: var(--cl-beige);
    @mixin transparent-bg-input var(--cl-black);

    &::placeholder {
      transition: all 0.3s ease;
      color: var(--cl-black);
      opacity: 0.5;
    }

    &:focus {
      border-color: var(--cl-leaf);
      caret-color: var(--cl-black);
      box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);
    }

    /*&.error {
          border-color: var(--cl-red);
          box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);
        }*/
  }

  textarea {
    padding-top: 11px;
    min-height: ac(100px, 96px);
    max-height: ac(100px, 96px);
    overflow-y: auto;
    resize: none;
    /*clip-path: inset(-2px -2px -2px -2px round 17px);*/

    scrollbar-width: thin;
    scrollbar-color: var(--cl-leaf) transparent;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--cl-blue);
      border-radius: 2px;
    }
  }

  &__help,
  &__error {
    margin-top: 5px;
    font-family: var(--font-main);
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 600;
  }

  &__help {
    color: var(--cl-white);
  }

  /*&__error {
      color: var(--cl-red);
    }*/
}

.is-safari {
  .meta-input {
    textarea {
      &::-webkit-scrollbar {
        width: initial;
      }

      &::-webkit-scrollbar-track {
        background: initial;
        border-radius: initial;
      }

      &::-webkit-scrollbar-thumb {
        background: initial;
        border-radius: initial;
      }
    }
  }
}

label.checkbox-wr,
.checkbox-wr {
  --checkbox-icon-size: ac(24px, 19px);
  --checkbox-mark-color: var(--cl-lilac, #e1e8ff);
  display: flex;
  gap: 12px;
  cursor: pointer;
  font-size: ac(20px, 16px);

  input {
    display: none;

    &:checked + .checkbox-icon {
      &::before {
        opacity: 1;
      }
    }
  }

  .checkbox-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: var(--checkbox-icon-size);
    height: var(--checkbox-icon-size);
    border: 2px solid var(--cl-navy);
    flex-shrink: 0;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -2px;
      top: -2px;
      width: var(--checkbox-icon-size);
      height: var(--checkbox-icon-size);
      background: var(--checkbox-mark-color);
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V22C0 22.5304 0.210714 23.0391 0.585786 23.4142C0.960859 23.7893 1.46957 24 2 24H22C22.5304 24 23.0391 23.7893 23.4142 23.4142C23.7893 23.0391 24 22.5304 24 22V2C24 1.46957 23.7893 0.960859 23.4142 0.585786C23.0391 0.210714 22.5304 0 22 0ZM17.7075 9.7075L10.7075 16.7075C10.6146 16.8005 10.5043 16.8742 10.3829 16.9246C10.2615 16.9749 10.1314 17.0008 10 17.0008C9.86858 17.0008 9.73846 16.9749 9.61706 16.9246C9.49566 16.8742 9.38537 16.8005 9.2925 16.7075L6.2925 13.7075C6.10486 13.5199 5.99944 13.2654 5.99944 13C5.99944 12.7346 6.10486 12.4801 6.2925 12.2925C6.48014 12.1049 6.73464 11.9994 7 11.9994C7.26536 11.9994 7.51986 12.1049 7.7075 12.2925L10 14.5863L16.2925 8.2925C16.3854 8.19959 16.4957 8.12589 16.6171 8.07561C16.7385 8.02532 16.8686 7.99944 17 7.99944C17.1314 7.99944 17.2615 8.02532 17.3829 8.07561C17.5043 8.12589 17.6146 8.19959 17.7075 8.2925C17.8004 8.38541 17.8741 8.49571 17.9244 8.6171C17.9747 8.7385 18.0006 8.86861 18.0006 9C18.0006 9.13139 17.9747 9.2615 17.9244 9.3829C17.8741 9.50429 17.8004 9.61459 17.7075 9.7075Z' fill='%235500EC'/%3E%3C/svg%3E%0A");
      background-position: center;
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  a {
    display: inline;
    transition: 0.3s;
    font-size: inherit;

    &:hover {
      color: var(--cl-turquoise);
    }
  }

  &:hover {
    color: var(--cl-blue);
  }
}

/*range styles*/
.double-range {
  padding-right: 0;
  margin-bottom: 16px;
  height: 2px;
  border-radius: 12px;
  border: none;
  box-shadow: none;
  background: lightgrey;

  .noUi-pips-horizontal {
    left: 12px;
    width: auto;
    right: 6px;
  }

  .noUi-marker {
    background-color: var(--cl-navy);
    width: 1px;
  }

  .noUi-marker-large {
    background-color: var(--cl-navy);
    height: 10px;
  }

  .noUi-value-horizontal {
    font-size: 10px;
    color: var(--cl-navy);

  }

  .noUi-connect {
    background: #8e96b4;
    margin-right: -4px;
  }

  .noUi-connects {
    background: var(--cl-navy);
  }

  .noUi-handle {
    background: var(--cl-turquoise);
    width: 24px;
    height: 24px;
    border: 3px solid var(--cl-navy);
    cursor: pointer;
    border-radius: 50%;
    box-shadow: none;
    top: 0;
    transform: translateY(-50%);

    &.noUi-handle-lower{
      transform: translate(0, -50%);
      &[aria-valuenow="0.0"] {
        transform: translate(29%, -50%);
      }
    }

    &.noUi-handle-upper {
      transform: translate(-50%, -50%);
    }

    &:before,
    &:after {
      content: none;
    }
  }

  &-container {
    width: 100%;
    max-width: 250px;
  }

  &-tooltip {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 28px;
    gap: 8px;

    i {
      display: block;
      flex: 1;
      height: 1px;
      background: var(--input-border-color);
    }
  }
}

.double-range-info {
  min-height: 48px;
  border: 1px solid var(--input-border-color);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 5px;
  text-align: center;
  align-items: center;
  background: var(--cl-white);
  min-width: 105.5px;
  line-height: 1;
  font-size: 16px;
  font-weight: 500;

  &-min {
  }
}

/*and of range styles*/

.input-wr {
  input {
    background: no-repeat;
    height: ac(56px, 48px);
    border-bottom: 1px solid var(--cl-navy);
    font-size: 16px;
    font-weight: 500;

    &::placeholder {
      color: inherit;
      opacity: 1;
    }
  }
}

.text-checkbox {
  align-items: center;
  cursor: pointer;
  max-width: 100%;
  padding-bottom: 4px;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
    min-width: ac(14px, 12px);
    width: ac(14px, 12px);
    height: ac(14px, 12px);
    position: relative;
    transition: all .3s ease;
    opacity: 0;

    i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      font-size: ac(14px, 12px);
      color: var(--cl-navy);
      transition: all .3s ease;
    }

    i.icon-minus {
      opacity: 0;
    }

    i.icon-minus {
      opacity: 0;
    }

    &:hover {
      i {
        color: var(--cl-purple);
      }
    }

    &.is-hidden {
      opacity: 0 !important;
      pointer-events: none;
    }

    &.active {
      i.icon-plus {
        opacity: 0;
      }

      i.icon-plus {
        opacity: 0;
      }

      i.icon-minus {
        opacity: 1;
      }

      i.icon-minus {
        opacity: 1;
      }
    }
  }

  &.parent-checkbox {
    padding-bottom: 0;
  }

  + ul {
    margin-top: 0;
    &.is-open {
      margin-top: ac(23px, 15px);
    }
  }
}

.filters-main-parent {
  opacity: 0;
  transition: all .4s ease;

  ul {
    transition: all .3s ease;
    padding-left: ac(32px, 20px);

    &.is-open {
      /*&:first-of-type {
        padding-top: 6px;
      }

      &:last-of-type {
        padding-bottom: 10px;
      }*/
    }

    ul {
      /*padding-top: 6px;
      padding-bottom: 16px;*/
      padding-left: 10px;
      ul {
        padding-left: 10px;

        &.is-open {
          &:first-of-type {
            padding-top: 0;
          }

          &:last-of-type {
            padding-bottom: 0;
          }
        }
      }

      &.is-open {
        &:first-of-type {
          padding-top: 0;
        }

        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }
  }

  li {

  }
}
