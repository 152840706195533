.first-section {
  position: relative;
  z-index: 10;
  overflow: hidden;
  background: #000;

  &__bg {
    position: absolute;
    inset: 0;
    /*left: 50%;
    transform: translateX(-55%);*/
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--mob {
      @mixin sm {
        display: none;
      }
    }

    &--desk {
      @mixin max-sm {
        display: none;
      }
    }

    @mixin max-sm {
      left: 0;
      right: 0;
      min-width: 100%;
      transform: translateX(0);
    }
  }

  &__wrap {
    min-height: calc(var(--vh, 1vh) * 100);
    height: auto;
    padding-top: 140px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @mixin media 551 {
      justify-content: flex-end;
      padding-bottom: 40px;
    }
  }

  &__title-container {
    width: 100%;
    position: relative;

    &:not(:last-child) {
      margin-bottom: ac(30px, 32px);
    }

    @mixin media 551 {
      order: 1;
    }
  }

  &__title {
    width: 100%;
    mix-blend-mode: overlay;

    h1 {
      color: var(--cl-white);
    }

    &--duplicate {
      font-size: ac(140px, 42px);
      font-weight: 800;
      line-height: normal;
      font-family: var(--font-second);
      color: var(--cl-white);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      @mixin xl {
        opacity: 0.1 !important;
      }

      @mixin media 374 {
        font-size: 40px;
      }

      @mixin media 330 {
        font-size: 38px;
      }
    }

    @mixin max-xs {

      .first-section {
        &__title {
          mix-blend-mode: soft-light;

          &--duplicate {
            mix-blend-mode: overlay;
          }
        }
      }
    }
  }

  &__text {
    &.small-size {
      @mixin max-sm {
        p, li {
          line-height: 24px;
        }
      }
    }
  }

  &__buttons {
    @mixin full-btn;

    &:not(:last-child) {
      margin-bottom: ac(51px, 32px);
    }

    @mixin media 551 {
      order: 3;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    width: 100%;
    max-width: ac(522px, 400px);

    @mixin media 551 {
      max-width: 100%;
      order: 2;
      margin-bottom: ac(40px, 32px);
    }
  }

  &__subtitle {
    font-family: var(--font-second);
    /*font-style: italic;*/
    font-weight: 600;
    font-size: ac(34px, 24px);
    line-height: ac(35px, 25px);
    margin-left: auto;
    text-align: right;

    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }

    @mixin media 551 {
      text-align: left;
      margin-left: 0;
    }
  }

  &__cubes {
    @mixin aspect-ratio-block 772, 725.38;
    position: absolute;
    right: ac(21px, 8px);
    bottom: ac(-21px, 274px);
    width: 60.22%;
    min-width: 581px;
    max-width: 772px;
    /*z-index: -1;*/
    mix-blend-mode: soft-light;

/*    @mixin max-sm {
      opacity: .7;
    }*/
  }

  &__cube {
    &:nth-child(1) {
      @mixin aspect-ratio 463.6, 487.98;
      width: 60.05%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      @mixin aspect-ratio 497.1, 557.63;
      width: 64.39%;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
  }
}