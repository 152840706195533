.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-navy) transparent;
  font-size: ac(20px, 18px);
  font-weight: 400;
  line-height: 1.5;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: var(--cl-white);
  color: var(--cl-navy);
  font-family: var(--font-main);
  min-height: calc(100 * var(--vh));
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--cl-navy);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cl-blue);
    border-radius: 0;
  }

  .simplebar-scrollbar::before {
     background-color: var(--cl-blue);
     opacity: 1!important;
     left: 1px;
     right: 1px;
  }

  .simplebar-track.simplebar-vertical{
     background: rgba(255,255,255, 0.2)!important;
     opacity: 1!important;
     width: 6px;
     border-radius: 5px;
     transform: translateX(8px);
  }



}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @mixin max-sm {
    overflow: hidden!important;
  }
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1400px;
  width: perc(1400);

  @mixin max-lg {
    width: 94%;
  }

  @mixin max-sm {
    width: 93.6%;
  }

  @mixin min-media 1450 {
    width: 94.58%;
    max-width: 1816px;
  }
}

.cont-second {
  width: 91.57%;
  margin: 0 auto;
  max-width: 1282px;

  @mixin max-sm {
    width: 93.16%;
  }
}

.cont-main {
  width: 100%;
  max-width: 1336px;
  margin: 0 auto;
}

section,
footer,
header {
  position: relative;
  width: 100%;
  max-width: 100vw;
}

.sections-container {
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;
}

.title-animation {
  opacity: 0;

  .title-line {
    position: relative;
    z-index: 1;

    &:before {
      bottom: 13%;
      right: ac(-18px, -4px);
      width: 0;
      height: ac(34px, 12px);
      opacity: 0;
      content: '';
      position: absolute;
      background: var(--cl-leaf);
      transition: width 1s ease, opacity 1s ease;
    }
  }

  &.animated {
    opacity: 1;

    .title-line {
      &:before {
        opacity: 1;
        width: calc(100% + (ac(18px, 4px) * 2));
      }
    }

    .splitting .word {
      animation: slide-in 0.6s cubic-bezier(0.5, 0, 0.5, 1) both;
      animation-delay: calc(150ms * var(--line-index));
      /*clip-path: polygon(110% 0, 110% 100%, 0 100%, 0 0);*/
    }
  }
}

.second-title-animation {
  transition: all 0.3s ease;
  opacity: 0;
  transform: scaleX(1.1) translateX(5%);
  transform-origin: top left;

  &.animated {
    opacity: 1;
    transform: scaleX(1) translateX(0);
  }
}

.sepia-background {
  position: absolute;
  inset: 0;
  background: url('../../assets/static/sepia-background.jpg') repeat;
  opacity: 0.08;
}

@keyframes slide-in {
  from {
    transform: translateX(0.5em) rotate(0);
    opacity: 0;
    /*clip-path: polygon(110% 80%, 110% 100%, 0 100%, 0 80%);*/
  }
}

.swiper {
  width: 100%;
  max-width: 100%;
}

.logo {
  transition: 0.3s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    transform: scale(1.04);
  }
}

.sticky-buttons-container {
  position: absolute;
  padding-top: 40vh;
  top: 0;
  right: 0;
  bottom: 0;
}

.sticky-buttons {
  position: sticky;
  right: 0;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 17px;
  z-index: 500;
  transform: rotate(-90deg) translateY(-50%) translate(50%);
  transform-origin: right;
  margin-top: 100vh;

  .btn {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24),
      -2px 0px 32px rgba(0, 0, 0, 0.12);
    height: ac(64px, 40px);

    &__text {
      font-size: ac(20px, 16px);
    }
  }

  @mixin max-sm {
    display: none;
  }
}

#bold-credits {
  width: 150px;
  transition: ease-in-out 0.25s;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  position: relative;
  .header:before{
    opacity: 0.6;
    background-color: var(--cl-navy);
  }
  .section-bg{
    z-index: -1;
  }

  .main {
    flex-grow: 1;
    padding: 0;
  }
}
.policy-page{
  .header{
    &::before{
      opacity: 0.8;
      background: var(--cl-navy);
    }
  }
}
.cookie-terms-section{
  padding-top: ac(250px, 140px);
  padding-bottom: ac(80px, 50px);
  .section-bg{
    z-index: -1;
  }

  ol, ul{
    padding-left: 23px;
    margin: 25px 0;
   li{
     list-style-position: outside;
   }
  }

  h1, h2, h3, h4, h5{
      line-height: 1.15;
      font-weight: 700;
      margin-bottom: 15px;
  }
  h1{
      font-size: ac(80px, 40px);
    margin-bottom: 30px;
  }
  h2{
      font-size: ac(70px, 35px);
    margin-bottom: 25px;
  }
  h3{
      font-size: ac(50px, 28px);
    margin-bottom: 20px;
  }
  h4{
      font-size: ac(45px, 24px);
    margin-bottom: 15px;
  }
  h5{
      font-size: ac(40px, 22px);
    margin-bottom: 10px;
  }
  h5{
      font-size: ac(35px, 20px);
    margin-bottom: 10px;
  }

  ul, ol{
      list-style: disc;
      padding-left: 23px;
      margin: 20px 0;
  }
  ol{
      list-style: decimal;
  }

  a:not([class]) {
      display: inline;
      color: var(--purple);
      text-decoration: underline;
      transition: 0.3s ease;

      &:hover {
          color: var(--orange);
      }
  }

  b, strong{
      font-weight: bold;
  }

  p {
      &:not(:last-child) {
          margin-bottom: 20px;
      }
  }



}

.bg-turquoise {
  background: var(--cl-turquoise);
}

.section-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
  }
}

.section-preview {
  position: relative;
  overflow: hidden;
  min-height: 200px;
  border-radius: 20px;

  .thumbnail-layer {
    opacity: 0.8;
  }

  &::before {
    content: '';
    display: block;
    padding-top: 28.67%;
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
  }
}

.page-pagination{
  padding-top: ac(64px, 40px);
  ul{
    display: flex;
    justify-content: center;
    gap: 8px;
    li{
      width: ac(44px, 34px);
      height: ac(44px, 34px);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      border-radius: 50%;
      font-weight: 500;
      font-size: ac(18px, 16px);
      &:not([class]){
        width: 28px;
      }
      &.active{
        font-weight: 700;
        font-size: ac(20px, 18px);
        color: var(--cl-white);
        background: var(--cl-blue);
      }
      &.page-pagination-button{
        font-size: 24px;
        cursor: pointer;

        &:hover{
          background: var(--cl-turquoise);
          color: var(--cl-white);
        }
      }
    }
  }

  &-item{
    cursor: pointer;
    &:hover{
      background: var(--cl-turquoise);
    }
  }
}
.fz-16{
  font-size: 16px!important;
 }
.success-title{
  font-size: ac(40px, 25px);
  font-weight: 600;
  column-span: 1/-1;
}
.single-page-404 {
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  h1{
    font-size: ac(80px, 30px);
  }

  h1,
  p {
    margin-bottom: 15px;
    padding: 0;
  }

  a {
    margin-top: 20px;
  }
}
.grecaptcha-badge{
  z-index: 51;
}