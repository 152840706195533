.jobs-page {
  .first-section {
    &__wrap {
      height: auto;
      min-height: ac(700px, 400px);
      padding-bottom: ac(130px, 80px);
    }

    &__title {
      span {
        &:nth-of-type(2) {
          /*margin-left: ac(54px, 0px);*/
          margin-left: 0 !important;
        }
      }
    }

    .fig-wrapper {
      position: absolute;

      &.fig-1 {
        left: ac(668px, 0px, 1440, 2560) !important;
        top: 0 !important;
        transform: translate(-27.3%, -23.5%) !important;
        width: perc(1124);
        max-width: 1124px;
      }

      &.fig-2 {
        right: ac(420px, 0px, 1440, 2560);
        top: 0 !important;
        transform: translate(11.4%, -17.7%) !important;
        width: perc(905);
        max-width: 905px;
      }
    }
  }
}

.job-results-wrapper {
  background: var(--cl-lilac);
  position: relative;

  .section-bg {
    z-index: 0;
  }
}

.job-result-section {
  padding:ac( 80px, 45px) 0;
  position: relative;
  z-index: 2;

  .title {
    font-size: ac(60px, 30px);
    font-weight: 800;
    line-height: 1.0333;
    font-family: var(--font-second);
    margin-bottom: 24px;
    color: var(--cl-blue);
  }

  .meta-select {
    margin-bottom: ac(64px, 35px);
  }

  @media (max-width: 1024px) {
    .cont-second {
      width: 100%;
    }
  }
}

.job-results {
  display: flex;
  justify-content: space-between;
  gap: ac(61px, 25px, 992, 1440);

  &-main {
    flex: 1;
    max-width: 848px;
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: ac(17.8px, 10px) ac(20px, 10px);
  }

  &-aside {
    width: ac(353px, 270px, 992, 1440);
    max-width: 100%;

    h3 {
      font-size: ac(44px, 35px);
      font-weight: 700;
      margin-bottom: ac(32px, 22px);
    }

    h4 {
      font-weight: 700;
      font-size: ac(24px, 22px);
      margin-bottom: 13px;
      line-height: 1.33;
      font-family: var(--font-second);
    }
  }

  &-filters {
    ul {
      display: flex;
      flex-direction: column;
      gap: ac(15px, 10px);
      padding-left: 4px;
    }
  }
}

.filters-group {
  padding-bottom: 43px;
  display: flex;
  flex-direction: column;
  gap: ac(23px, 15px);
  grid-row-gap: ac(23px, 15px);

  &-toggle {
    margin-top: 7px;
  }

  &-type {
    h4 {
      margin-bottom: 4px;
    }

    ul {
      gap: ac(23px, 15px);
    }
  }

  &-range {
    gap: 4px;
  }

  &-location {
    gap: 4px;

    .filters-group-toggle.active {
      margin-top: 27px;
    }
  }
  .checkbox-wr{
    i{
      font-style: normal;
      font-size: 18px;
    }
  }
}

.filters-list-hidden {
  &:not(.active) {
    display: none;
  }
}

.upload-cv {
  transform: translate(0);
  display: flex;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: -184px;
  z-index: 3;

  &-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }

  .title {
    mix-blend-mode: overlay;
    color: var(--cl-white);
    margin-bottom: 24px;
  }

  &-thumbnail {
    flex-shrink: 0;
    width: 594px;
    max-width: 50%;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      padding-top: 93.94%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }

  &-text {
    flex: 1;
    padding: ac(120px, 50px) ac(56px, 20px) ac(60px, 35px) ac(60px, 20px);
    color: var(--cl-white);

    .btn {
      margin-top: 40px;
      .btn__icon{
        font-size: 20px;
      }
    }
  }

  .section-description {
    font-size: ac(20px, 16px);

    p {
      &:first-of-type {
        font-size: ac(34px, 24px);
        font-weight: 600;
        line-height: 1.29;
        margin-bottom: 8px;
        font-family: var(--font-second);
      }
    }
  }
}

@media (max-width: 992px) {
  .job-results{
    flex-direction: column-reverse;
    &-aside{
      max-width: 100%;
      width: 100%;

    }
    &-main{
      max-width: 100%;
    }
    &-filters{
      display: flex;
      flex-wrap: wrap;
      gap: 25px 50px;
      h3{
        width: 100%;
        margin-bottom: 0;
      }
    }
  }
  .filters-group{
    gap: 8px!important;
    padding-bottom: 0;
    h4{
      margin-bottom: 10px!important;
    }
    ul{
      gap: 8px!important;
    }
    &-range{
      order: 2;
      width: 100%;
      max-width: 100%;
      .double-range-container{
        max-width: 100%;
      }
    }
    &-toggle{
      margin-top: 10px!important;
    }
  }

}

@media (max-width: 768px) {
  .upload-cv{
    flex-direction: column-reverse;
    &-thumbnail{
      width: 100%;
      max-width: 100%;
      &::before{
        padding-top: 63%;
      }
      img{
        object-position: center 20%;
      }
    }
  }
}

@media (max-width: 650px) {
  .job-results-grid{
    grid-template-columns: minmax(0, 420px);
    justify-content: center;
  }
  .filters-group{
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 450px) {
  .upload-cv-text .btn {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 370px) {
  .jobs-page{
    .first-section__title h1{
      font-size: 34px;
    }
  }
}

.double-range-parent {

  .double-range-container {
    transition: .3s;
    opacity: 0;
    margin-top: 0;
    max-height: 0;
    pointer-events: none;
  }

  &.active {
    .double-range-container {
      margin-top: ac(20px, 15px);
      max-height: 100px;
      opacity: 1;
      pointer-events: auto;
    }
  }
}