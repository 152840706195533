.job-details-page {
  overflow: visible !important;

  .first-section {
    &__bg {
      img {
        object-position: center bottom;
      }
    }

    &__wrap {
      height: auto;
      min-height: ac(600px, 470px);
      padding-bottom: ac(128px, 50px, 1100, 1440);
      justify-content: flex-end;
      flex-direction: column !important;
      @media (max-width: 650px) {
        min-height: calc(100 * var(--vh, 1vh));
      }
    }
    .first-section__title-container{
      margin-bottom: 20px!important;
    }

    &__title {
      mix-blend-mode: normal;

      &-container {
        order: 0;
        margin-bottom: ac(-22px, 0px, 1200, 1440) !important;
      }

      h1 {
        font-size: ac(80px, 30px);
        line-height: 1.05;
        font-weight: 700;
      }

      span {
        &:nth-of-type(2) {
          margin-left: 0;
        }
      }
    }

    .fig-wrapper {
      position: absolute;
      pointer-events: none;

      &.fig-1 {
        left: 0 !important;
        top: 0 !important;
        transform: translate(2.7%, 31.5%) !important;
        width: perc(732);
        max-width: 732px;
      }

      &.fig-2 {
        right: 0;
        top: 50% !important;
        transform: translate(34%, -52%) !important;
        width: perc(935);
        max-width: 935px;
      }
    }

    .btn:not(.btn-transparent) {
      min-width: 145px;

      .btn__text {
        font-size: 16px;
      }
    }
  }

  .roles-section {
    overflow: visible;
    padding-bottom: 1px;
    padding-top: 0;
    &-cont-wr {
      padding-top: ac(120px, 80px);
      overflow: hidden;
    }

    &__bg-decor {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;

      &::before {
        display: none;
      }

      img {
        width: 1411px;
        max-width: 117%;
        top: 50%;
        left: 50%;
        transform: translate(-34.9%, -31.7%);
        height: auto;
        object-fit: contain;
      }

      &-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        overflow: hidden;
      }
    }

    .upload-cv-section {
      padding-top: ac(120px, 50px);
    }
  }
}

.job-details-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 35px;
  width: 100%;
  flex-wrap: wrap;

  .buttons {
    margin: 0 !important;
  }

  ul {
    flex: 1;
    max-width: 593px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: ac(20px, 15px);

    li {
      font-size: ac(24px, 18px);
      line-height: 1.333;
      padding: 8px;
      position: relative;
      font-family: var(--font-second);
      font-weight: 700;

      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: var(--cl-lilac);
        mix-blend-mode: overlay;
      }
    }
  }
}

.job-details-section {
  position: relative;
  transform: translate(0);
  padding: 80px 0 ac(120px, 80px);

  .section-bg {
    z-index: -1;
  }
}

.article {
  display: flex;
  justify-content: space-between;
  gap: ac(92px, 35px, 992, 1440);

  &-main {
    flex: 1;

    .buttons {
      margin: 48px 0;
    }
  }

  &-content {
    font-size: ac(20px, 16px);

    h1,
    h2,
    h3,
    h4,
    h5 {
      line-height: 1.5;
      font-weight: 800;
      font-family: var(--font-second);
      margin-bottom: 24px;
      color: var(--cl-blue);
    }

    h1 {
      font-size: ac(70px, 35px);
      line-height: 1.0333;
    }

    h2 {
      font-size: ac(60px, 32px);
      line-height: 1.0333;
    }

    h3 {
      font-size: ac(34px, 28px);
      margin-top: ac(48px, 35px);
      margin-bottom: 16px;
      font-family: var(--font-main);
    }

    h4 {
      font-size: ac(32px, 26px);
    }

    h5 {
      font-size: ac(30px, 24px);
    }

    h6 {
      font-size: ac(28px, 22px);
    }

    p {
      font-weight: 300;
      line-height: 1.5;
      margin-bottom: ac(37px, 25px);

      &:first-of-type {
        font-weight: 400;
        font-size: ac(30px, 20px);
        margin-bottom: ac(48px, 35px);
      }
    }

    ul,
    ol {
      margin-top: 27px;
      margin-bottom: 51px;
      display: flex;
      flex-direction: column;
      gap: ac(23px, 15px);
    }

    ul {
      padding-left: 10px;

      li {
        position: relative;
        padding-left: ac(27px, 20px);

        &::before {
          content: '';
          display: block;
          width: 14px;
          height: 14px;
          background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.67797 8.32394C5.35344 8.95775 6.11334 9.29578 6.95768 9.29578C7.88644 9.29578 8.64635 8.95775 9.32182 8.32394C9.95507 7.69014 10.2928 6.88732 10.2928 6C10.2928 5.11268 9.95507 4.35211 9.32182 3.67606C8.68857 3.04225 7.88644 2.70423 6.95768 2.70423C6.11334 2.70423 5.35344 3.04225 4.67797 3.67606C4.0025 4.30986 3.70698 5.11268 3.70698 6C3.70698 6.88732 4.04472 7.64789 4.67797 8.32394ZM6.95768 12C5.39565 12 4.0025 11.4085 2.77822 10.2254C1.55393 9.04225 0.962891 7.64789 0.962891 6C0.962891 4.35211 1.55393 2.95775 2.77822 1.77465C4.0025 0.591549 5.39565 0 6.95768 0C8.64635 0 10.0817 0.591549 11.2638 1.77465C12.4459 2.95775 13.0369 4.35211 13.0369 6C13.0369 7.64789 12.4459 9.04225 11.2638 10.2254C10.0817 11.4085 8.64635 12 6.95768 12Z' fill='%23061344'/%3E%3C/svg%3E%0A");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          left: 0;
          top: ac(5px, 3px);
        }
      }
    }

    ol {
      padding-left: 23px;
      list-style: decimal;
    }

    a:not([class]) {
      display: inline;
      color: var(--cl-blue);
      text-decoration: underline;
      transition: 0.3s ease;
      font-size: inherit;

      &:hover {
        color: var(--cl-turquoise);
      }
    }

    b,
    strong {
      font-weight: bold;
    }
  }

  &-aside {
    width: ac(341px, 280px);
    max-width: 100%;

    .teammate-card {
      position: sticky;
      top: 110px;

      &-logo {
        max-width: 87px;
        margin-bottom: 16px;
      }

      &-thumbnail {
        &-wrapper {
          margin-bottom: 32px;
        }
      }

      &-name {
        font-size: ac(30px, 24px);
        margin-bottom: 10px;
      }

      &-position {
        font-size: ac(22px, 20px);
      }
    }
  }

  &-socials {
    display: flex;
    align-items: center;

    flex-wrap: wrap;
    gap: 24px;

    span {
      font-size: ac(18px, 16px);
      font-weight: 500;
      line-height: 1.44;
    }

    a {
      color: var(--cl-blue);

      &:hover {
        color: var(--cl-turquoise);
      }
    }
  }
}

@media (max-width: 1100px) {
  .job-details-info {
    flex-direction: column-reverse;
    align-items: flex-start;
    width: 100%;
    padding-top: 40px;

    ul {
      width: 100%;
    }
  }
}

@media (max-width: 992px) {
  .article {
    flex-direction: column;

    &-aside {
      width: 100%;
    }
  }
}

@media (max-width: 650px) {
  .job-details-info {
    ul {
      grid-template-columns: 1fr;
    }

    .btn {
      width: 100%;
    }
  }
}

@media (max-width: 420px) {
  .article-main .buttons {
    grid-gap: 20px;
  }

  .article-aside {
    .teammate-card {
      width: 100%;
      max-width: 100%;
    }
  }
}
