.join-team-page {
  .first-section__bg {
    background: linear-gradient(107.53deg, #061344 0%, #8900ea 99.67%);
  }

  .first-section {
    &__wrap {
      padding-bottom: ac(243px, 130px);
      height: auto;
      min-height: calc(var(--vh, 1vh) * 100 + ac(51px, 0px));
    }

    .fig-wrapper {
      width: perc(1589);
      max-width: 1589px;
      position: absolute;
      left: 50%;
      top: 51px;
      z-index: 0;
      transform: translateX(-10.5%);
      min-width: 840px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .cont {
      position: relative;
    }

    &__subtitle {
      text-align: left;
    }

    .first-section__title {
      mix-blend-mode: normal;

      h1 {
        /*color: #990fff;*/
        color: #AA05FF;

        span {
          &:nth-of-type(2) {
            margin-left: ac(69px, 20px);
          }
        }
      }
    }

    & + .journey-section-wrapper {
      .journey-section {
        .cont {
          margin-top: ac(-212px, -100px);
          position: relative;
          z-index: 11;
        }
      }
    }
  }
    .footer__wrap{
        padding-top: ac(118px, 60px) !important;
    }
}

.thumbnail-layer {
  position: absolute;
  inset: 0;
  z-index: 2;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.journey-section {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  background: var(--cl-lilac);

  .cont {
    display: flex;
    justify-content: space-between;
    border-radius: 12px 0 0 12px;
    background: var(--cl-white);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.04);
    left: 20px;
  }

  &-text {
    flex: 1;
    padding: ac(102px, 35px) ac(60px, 20px);

    p {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }



  &-thumbnail {
    flex-shrink: 0;
    width: 648px;
    max-width: 48%;
    position: relative;
    overflow: hidden;
    transform: translateX(0);

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: none;
    }
  }

  .sub-title {
    font-size: ac(44px, 25px);
    font-weight: 700;
    line-height: 1.2727;
    margin-bottom: ac(24px, 14px);
  }

  .title-animation {
    font-size: ac(80px, 35px);
    color: var(--cl-blue);
    font-weight: 700;
    line-height: 1.05;
    margin-bottom: 24px;
  }

  .section-description {
    font-weight: 300;
  }
}

.benefits-section {
  --mb: 270px;
  padding-bottom: var(--mb);
  margin-bottom: calc(var(--mb) * -1);

  background: var(--cl-lilac);

  .title-animation {
    margin-bottom: ac(56px, 40px);
  }

  .cont {
    position: relative;
    z-index: 1;
    padding: ac(100px, 50px) 0 ac(80px, 50px);
  }

  .section-bg {
    img {
      object-position: center top;
    }
  }

  .title-animation {
    color: var(--cl-blue);
    text-align: center;
  }

  .swiper-slide {
    height: auto;
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    .benefit-card {
      width: 100%;
    }
  }
}

.benefit-card {
  position: relative;
  padding: ac(48px, 35px) ac(34px, 15px) ac(56px, 35px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: auto;

  &-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;


    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-icon {
    padding: 0 9px 9px 0;
    width: 112px;
    height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../images/ben-border.svg') no-repeat center /
      contain;
    margin-bottom: 24px;

    img {
      max-width: 90%;
      max-height: 70%;
    }
  }

  &-title {
    width: 100%;
    text-align: center;
    font-size: ac(34px, 24px);
    font-weight: 800;
    line-height: 1.5;
    margin-bottom: 12px;
  }

  &-descroption {
    width: 100%;
    text-align: center;
    font-weight: 300;
    --line-count:5;
    --fz: ac(20px, 16px);
    --line-height: 1.5;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));

    p{
      line-height: inherit;
    }
    &.simplebar-scrollable-y{

    }
    .simplebar-scrollbar::before {

    }

    .simplebar-track.simplebar-vertical{

    }
  }
}

.benefits-swiper-wr {
  .swiper {
    overflow: visible;
    width: calc(100% - ac(40px, 0px));
    @media (max-width: 1023px){
      &-slide{
        width: 320px;
        max-width: 100%;
      }
    }
  }

  .slider-buttons {
    margin: 0 auto;
    width: fit-content;
  }

  .slider-btn {
    margin-top: 50px;
  }

}

.ts-section {
  .cont {
    padding: ac(144px, 70px) 0 ac(121px, 40px);
    position: relative;
    border-radius: 12px;
    background: linear-gradient(103.02deg, #061344 -1.78%, #8900ea 119.69%);
  }

  &-header {
    display: flex;
    justify-content: space-between;
    gap: 35px;
    align-items: flex-end;
    padding-bottom: ac(88px, 45px);
  }

  .title-wr {
    position: relative;
    width: ac(925px, 377px);
  }

  .title-animation {
    color: var(--cl-white);
    padding-right: 100px;
    position: relative;
    z-index: 1;
  }

  .ts-section-title-duplicate {
    z-index: 0;
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    font-size: ac(124px, 53px);
    font-family: var(--font-second);
    font-weight: 800;
    line-height: 1.035;
    color: var(--cl-white);
    mix-blend-mode: overlay;
    opacity: 0.3;
    padding-left: ac(35px, 10px);

    span {
      &:nth-of-type(1) {
        margin-left: ac(85px, 25px);
      }
    }
  }

  .slider-buttons {
    margin-bottom: ac(-26px, 0px);
  }

  .bg-layer {
    width: 340px;
    max-width: 25%;
    min-width: 120px;
    position: absolute;
    z-index: 0;
    right: ac(150px, 0px);
    bottom: ac(183px, 50px);

    img {
      width: 100%;
      height: auto;
    }
  }
}

.ts-swiper-wr {
  .swiper {
    margin: 0;
  }
}

.ts-swiper-outer {
  width: ac(738px, 408px);
  max-width: 100%;
}

.ts-swiper-header {
  position: relative;
  display: flex;
  gap: 28px;
  flex-shrink: 0;
  align-items: center;
  margin-bottom: 3px;

  img {
    width: ac(64px, 30px);
    height: auto;
    z-index: 1;

    &:nth-of-type(2) {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      transform: translate(10%, 10%);
      mix-blend-mode: overlay;
    }
  }

  &::after {
    content: '';
    display: block;
    flex: 1;
    height: 1px;
    mix-blend-mode: overlay;
    background: var(--cl-lilac);
  }
}

.ts-card {
  padding-top: ac(28px, 20px);

  &-description {

    color: var(--cl-white);
    margin-bottom: ac(56px, 35px);

    --line-count:7;
    --fz: ac(30px, 18px);
    --line-height: 1.5;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
    a{
      display: inline-block;
      transition: 0.3s;
      text-decoration: underline;
      font-size: inherit;
      &:hover{
        color: var(--cl-turquoise);
      }
    }


    &.simplebar-scrollable-y{
        padding-right: 10px;
    }

    .simplebar-track.simplebar-vertical{
        transform: none;
    }
  }

  &-author {
    display: flex;
    gap: ac(24px, 14px);
    color: var(--cl-white);
    align-items: center;

    &-thumbnail {
      flex-shrink: 0;
      width: ac(96px, 80px);
      position: relative;
      overflow: hidden;
      border-radius: 50%;

      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
      }
    }

    &-name {
      font-family: var(--font-second);
      font-size: ac(34px, 18px);
      font-weight: 600;
      line-height: 1.29;
      margin-bottom: ac(8px, 4px);
    }

    &-position {
      font-weight: bold;
      font-size: ac(20px, 14px);
      line-height: 1.5;
    }
  }
}

.wlc-section.wlc-section-team {
  background: none;
    padding-top: ac(119px, 68px);
    padding-bottom: 47px;
    overflow: hidden;

    .bg-layer-wr{
        transform: translate(-62%, -44%);
        overflow: visible;
        z-index: 0;
    }
    .cont{
        position: relative;
        z-index: 1;
    }

  .title-animation {
      text-align: left;
      span {
          &:nth-of-type(2){
              margin-left: ac(68px, 25px);
          }
      }
  }
}


.ts-section-wrapper {
  position: relative;
  padding-top: 1px;

  .section-bg {
    bottom: 0;
    left: 0;
    top: auto;
    height: calc(100% - 270px);

    img {
      object-position: left bottom;
    }
  }
}

.roles-section.roles-section-team{
  padding: 0;
  overflow: visible;
  .roles-section-inner{
    overflow: hidden;
    padding: ac(121px, 50px) 0;
  }

  .bg-layer{
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(39%, -41%);
    width: perc(188);
    min-width: 80px;
  }


    .roles-section__bg-decor{
        top: ac(19px, 101px);
    }
}

@media (max-width: 768px) {
  .journey-section {
    .cont{
      flex-direction: column;
      left: ac(23px,10px, 320, 768);
    }
    &-text{
      padding-bottom: 45px;
      padding-top: 50px;
    }
    &-thumbnail{
      width: 100%;
      max-width: 100%;
    }
  }
}

@media (max-width: 650px) {
  .ts-section {
    &-header {
      flex-direction: column;
      align-items: flex-start;

      .slider-btn {
        margin: 0;
      }
    }

    .bg-layer {
      bottom: auto;
      top: ac(132px, 146px, 375, 650);
    }

    .ts-section-title-duplicate {
      font-size: ac(56px, 40px, 320, 650);
    }
  }

  .ts-swiper-outer {
    width: 100%;
  }
  .join-team-page .first-section .fig-wrapper{
    transform: translateX(-27.5%);
  }
}
