.job-card {
  border: 1px solid rgba(6, 19, 68, 0.2);
  border-radius: 16px;
  padding: ac(29px, 23px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: var(--cl-white);
  height: auto;
  transition: border-color .3s ease;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  perspective: 1000px;
  transform-style: preserve-3d;

  &__list {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    transform: translateZ(0);
    transition: transform .4s ease;

    li {
      font-size: ac(20px, 16px);
      line-height: normal;
      font-weight: 700;

      &:not(:last-child) {
        margin-bottom: ac(7px, 2px);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(24px, 16px);
    }
  }

  &__title {
    font-size: ac(30px, 24px);
    line-height: normal;
    font-weight: 800;
    transition: color .3s ease, transform .4s ease;
    color: var(--cl-navy);
    transform: translateZ(0);
    @mixin max-line-length 4;

    &:not(:last-child) {
      margin-bottom: ac(30px, 20px);
    }

    @mixin max-sm {
      line-height: 34px;
    }
  }

  &__btn {
    height: ac(55px, 54px);
    padding: 13px 31px;
    transform: translateZ(0);
    transition: transform .4s ease, background-color .3s ease;
  }

  &:hover {
    border-color: var(--cl-white);

    .job-card__title {
      color: var(--cl-blue);
      transform: translateZ(20px);
    }

    .job-card__list {
      transform: translateZ(30px);
    }

    .job-card__btn {
      transform: translateZ(40px);
    }
  }
}