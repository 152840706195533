.logos-section {
  padding-top: ac(120px, 48px);
  padding-bottom: ac(120px, 48px);
  position: relative;
  z-index: 10;
  overflow: hidden;


  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__slider-buttons {
    .slider-btn {
      margin-top: ac(32px, 26px);
    }
  }

  &__slider {
    overflow: visible;

    &:not(.swiper-initialized) {
      display: none;
    }
  }

  &__item {
    padding: ac(16px, 12.59px);
    border-radius: 10px;
    background: linear-gradient(135deg, rgba(121,0,231,1) 0%, rgba(27,212,237,1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      position: absolute;
      inset: 2px;
      border-radius: 8px;
      background: var(--cl-white);
      transition: background-color .3s ease;
      z-index: 1;
      content: "";
    }

    &:hover {
      &:after {
        background-color: var(--cl-lilac);
      }
    }
  }

  &__item-image {
    height: ac(118px, 93px);
    width: auto;
    position: relative;
    z-index: 2;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  @mixin media 551 {
    .logos-section {
      &__slider {
        max-width: 240px;
      }
    }
  }
}