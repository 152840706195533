.post-details-page {
  overflow: visible!important;
  .first-section {
    &__wrap {
      height: auto;
      min-height: ac(700px, 420px);
      padding-bottom: ac(72px, 60px);
    }

    &__title {
      mix-blend-mode: normal;

      &-container {
        max-width: 46%;
      }

      h1 {
        font-size: ac(60px, 30px);
        line-height: 1.0333;
      }
    }

    .hero-post-info {
      color: var(--cl-white);
      font-size: 16px;
      margin-bottom: ac(40px, 25px);
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    .simple-btn {
      margin-top: ac(38px, 35px);
    }
  }

  .footer__wrap {
    padding-top: ac(120px, 60px) !important;
  }
}

.post-details-section {
  position: relative;
  transform: translate(0);
  padding: 80px 0 120px;

  .section-bg {
    z-index: -1;
  }

  .article {
    gap: ac(158px, 35px);

    &-content p:first-of-type {
      font-size: inherit;
      font-weight: 300;
      margin-bottom: 16px;
    }

    &-aside {
      width: 273px;
    }
  }
}

.post-hero-thumbnail {
  position: absolute;
  right: 0;
  top: 0;
  width: 770px;
  max-width: 49.3%;
  height: 100%;
  z-index: 0;
  overflow: hidden;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
  }
}

.article-author {
    position: sticky;
  top: 110px;
  h3 {
    font-size: ac(24px, 20px);
    font-weight: 700;
    font-family: var(--font-second);
    line-height: 1.333;
    margin-bottom: 24px;
    color: var(--cl-blue);
  }

  &-wr {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 34px;
  }

  &-text {
    p {
      font-size: ac(20px, 18px);
      font-weight: 700;
    }

    span {
      font-size: ac(16px, 14px);
      font-weight: 400;
      color: var(--cl-blue);
    }
  }

  &-thumbnail {
    flex-shrink: 0;
    width: 64px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }

  &-social {
    display: flex;
    flex-wrap: wrap;

    li {
      display: grid;
      place-items: center;
      width: 56px;
      height: 32px;
      border-right: 1px solid var(--cl-turquoise);
      border-left: 1px solid var(--cl-turquoise);
      margin-left: -1px;
      line-height: 1;

      a {
        font-size: 20px;
        transition: color 0.3s;

        &:hover {
          color: var(--cl-blue);
        }

        .icon-email {
          font-size: 90%;
        }
      }

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

.news-section {
  padding: ac(120px, 60px) 0 80px;
  transform: translate(0);
  overflow: hidden;

  .section-bg {
    z-index: -1;

    .bg-layer {
      object-position: center top;
      mix-blend-mode: overlay;
      opacity: 0.6;
      width: perc(2107);
      max-width: revert;
    }
  }

  .title-wr {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 25px 35px;
    flex-wrap: wrap;
    padding-bottom: ac(50px, 30px);

    .buttons-wr {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 35px 24px;
      flex-shrink: 0;
      max-width: 100%;
      margin-left: auto;

      .slider-btn {
        margin: 0;
      }
    }
  }
}

.news-swiper-wr {
  .news-card-title {
    margin-bottom: ac(46px, 36px);
  }
}

@media (max-width: 1024px) {
  .news-swiper-wr {
    .news-card-title {
    }

    .swiper {
      overflow: visible;

      &-slide {
        width: 375px;
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 650px) {
  .post-details-page .first-section__wrap {
    padding-top: 80px;
    padding-bottom: 40px;
    min-height: 0;
    height: auto;
    flex-direction: column-reverse;

    .first-section__title-container {
      max-width: 100%;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  .post-hero-thumbnail {
    position: relative;
    width: calc(100% + 50px);
    left: -25px;
    max-width: revert;
    margin-bottom: 35px;
    order: 1;

    &::before {
      content: '';
      display: block;
      padding-top: 77%;
    }

    img {
      object-position: center 10%;
    }
  }

  .news-section .title-wr {
    flex-direction: column;

    .buttons-wr {
      flex-direction: column;
      gap: 20px;
    }
  }
}
