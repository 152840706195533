.credentials-section {
  position: relative;
  z-index: 2;
  padding-top: ac(40px, 20px);
  padding-bottom: ac(40px, 32px);
  overflow: visible;

  &__page-decor {
    @mixin aspect-ratio 766.77, 879.48;
    position: absolute;
    z-index: -1;
    /*top: ac(-170px, -100px);*/
    top: ac(-210px, -180px);
    width: ac(766.77, 400px);
    left: ac(-206px, -100px);

    @mixin max-sm {
      left: 0;
      width: 88.80%;
      max-width: 400px;
    }
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__title {
    width: 100%;


    &:not(:last-child) {
      margin-bottom: ac(58px, 22px);
    }

    /*.ml {
      margin-left: ac(76px, 32px);
    }*/

    h2 {
      br {
        + span {
          margin-left: ac(72px, 32px);
        }
      }
    }
  }

  &__slider-buttons {
    .slider-btn {
      margin-bottom: ac(40px, 38px);
    }
  }

  &__slider {
    overflow: visible;
    margin-left: 0;

    &:not(.swiper-initialized) {
      display: none;
    }

    @mixin media 451 {
      max-width: 184px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__item-count {
    padding-top: ac(7px, 1px);
    padding-bottom: ac(5px, 2px);
    padding-left: ac(18px, 10px);
    position: relative;
    font-size: ac(70px, 40px);
    font-weight: 600;
    line-height: ac(80px, 48px);

    &:before {
      top: 0;
      bottom: 0;
      left: 0;
      content: "";
      position: absolute;
      background: var(--cl-leaf);
      width: ac(4px, 2.5px);
      transform: translateX(-50%);

      @mixin max-sm {
        transform: translateX(0);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(28px, 14px);
    }
  }

  &__item-title {
    font-family: var(--font-main);
    font-weight: 700;
    font-size: ac(24px, 16px);
    line-height: 1.42;

    &:not(:last-child) {
      margin-bottom: ac(15px, 6px);
    }
  }

  &__item-text {
    p, li {
      font-size: ac(16px, 14px);

      @mixin max-sm {
        line-height: 1.42;
      }
    }

    &[data-simplebar] {
      max-height: calc((ac(16px, 14px) * 1.62) * 8);
      width: calc(100% + 10px);
      padding-right: 10px;

      .simplebar-track.simplebar-vertical {
        width: ac(4px, 3px);
        background: var(--cl-beige);
        border-radius: 0;
        .simplebar-scrollbar {
          background: var(--cl-leaf);
          border-radius: 0;
          &:before {
            content: none;
          }
        }
      }

      @mixin max-sm {
        max-height: calc((ac(16px, 14px) * 1.42) * 8);
      }
    }
  }
}